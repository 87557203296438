import { AppProviders } from './AppProviders';
import Routes from './routes';
import { useAdminMediaAuth } from '@pmspds/core-lib/api/clients';

import '@/themes/devias/global.css';
import './App.css';

function App() {
  useAdminMediaAuth();
  return (
    <AppProviders>
      <Routes />
    </AppProviders>
  );
}

export default App;
