import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const endpoints = makeApi([
  {
    method: "get",
    path: "/api/media/download-link",
    alias: "mediaGetDownloadUrl",
    description: `get signed url by file`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "file",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "expirationInMinutes",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({ expiresAt: z.string(), url: z.string() })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/private-media/upload",
    alias: "privateMediaUpload",
    description: `Save one private media on the server`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ file: z.string() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ url: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/public-media/upload",
    alias: "publicMediaUpload",
    description: `Save one public media on the server`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ url: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
