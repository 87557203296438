import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const uploadedMediaAuth_Body = z
  .object({ mediaURL: z.string().nullable(), usdoID: z.number().nullable() })
  .partial()
  .passthrough();
const authPasswordRecover_Body = z
  .object({
    AccessModel: z.string().nullish(),
    applID: z.string(),
    email: z.string(),
    resetURL: z.string().nullish(),
  })
  .passthrough();
const authPasswordReset_Body = z
  .object({ password: z.string(), verification: z.string() })
  .passthrough();
const authLogin_Body = z
  .object({
    applID: z.string(),
    coord: z.string().nullish(),
    deviceID: z.string(),
    deviceName: z.string(),
    email: z.string(),
    ip: z.string(),
    location: z.string(),
    password: z.string(),
    rememberMe: z.boolean(),
  })
  .passthrough();
const authEmailTokenAuth_Body = z
  .object({ applID: z.string(), email: z.string() })
  .passthrough();
const authEmailTokenSignin_Body = z
  .object({
    acveID: z.string(),
    coord: z.string().nullish(),
    deviceID: z.string(),
    deviceName: z.string(),
    ip: z.string(),
    location: z.string(),
    verification: z.string(),
  })
  .passthrough();
const documentCreate_Body = z
  .object({
    data: z.unknown(),
    description: z.string(),
    doteID: z.string(),
    formSchema: z.unknown(),
    permissionMode: z.string(),
    printSchema: z.unknown(),
    title: z.string(),
    uiSchema: z.unknown(),
  })
  .passthrough();
const authCreateAccount_Body = z
  .object({
    applID: z.string(),
    coord: z.string().nullish(),
    deviceID: z.string(),
    deviceName: z.string(),
    email: z.string(),
    ip: z.string(),
    location: z.string(),
    name: z.string(),
    password: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_GeoLocationView = z
  .object({
    address: z
      .object({
        cep: z.string().nullish(),
        city: z.string(),
        complement: z.string().nullish(),
        coordinate: z.string(),
        country: z.string(),
        district: z.string().nullish(),
        number: z.string().nullish(),
        state: z.string(),
        stateAcronym: z.string(),
        street: z.string().nullish(),
      })
      .passthrough(),
    addressFormatted: z.string(),
  })
  .passthrough();
const surveyResponse_Body = z
  .object({
    clientDevice: z.string(),
    clientIP: z.string(),
    clientUID: z.string(),
    docuID: z.string().nullish(),
    document: z
      .object({
        data: z.unknown(),
        description: z.string(),
        doteID: z.string(),
        formSchema: z.unknown(),
        permissionMode: z.string(),
        printSchema: z.unknown(),
        title: z.string(),
        uiSchema: z.unknown(),
      })
      .passthrough()
      .nullish(),
    doteID: z.string(),
    metadata: z.unknown(),
    sursID: z.string(),
    survID: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_SurveyForm = z
  .object({
    deletedAt: z.string().nullish(),
    doteID: z.string(),
    position: z.number(),
    survID: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_PendingTerm = z
  .object({ slug: z.string(), termID: z.number(), title: z.string() })
  .passthrough();
const usersGetProfileMe_Body = z
  .object({
    avatar: z.string().nullable(),
    info: z.object({}).partial().passthrough().nullable(),
    name: z.string().nullable(),
  })
  .partial()
  .passthrough();

export const schemas = {
  uploadedMediaAuth_Body,
  authPasswordRecover_Body,
  authPasswordReset_Body,
  authLogin_Body,
  authEmailTokenAuth_Body,
  authEmailTokenSignin_Body,
  documentCreate_Body,
  authCreateAccount_Body,
  github_com_falqondev_PMSPDS_backend_service_GeoLocationView,
  surveyResponse_Body,
  github_com_falqondev_PMSPDS_backend_service_SurveyForm,
  github_com_falqondev_PMSPDS_backend_service_PendingTerm,
  usersGetProfileMe_Body,
};

const endpoints = makeApi([
  {
    method: "post",
    path: "/api/auth-media",
    alias: "uploadedMediaAuth",
    description: `Authenticate media server access`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: uploadedMediaAuth_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ jwt: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/auth/flow/:provider",
    alias: "auth3rdParty",
    description: `Login with a providers Oauth flow`,
    requestFormat: "json",
    parameters: [
      {
        name: "provider",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "applID",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/auth/flow/:provider/callback",
    alias: "auth3rdParty",
    description: `Login with a providers Oauth flow`,
    requestFormat: "json",
    parameters: [
      {
        name: "provider",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "state",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/auth/one-time-login",
    alias: "authOneTimeLogin",
    description: `Authenticate with single use token`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ token: z.string() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/auth/password-recover",
    alias: "authPasswordRecover",
    description: `Start password recovery for account with given email`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: authPasswordRecover_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acveID: z.string(),
                createdAt: z.string(),
                type: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/auth/password-reset/:resetID",
    alias: "authPasswordReset",
    description: `Reset password for account with given email`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: authPasswordReset_Body,
      },
      {
        name: "resetID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z.object({}).partial().passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/auth/password-reset/:resetID/validate",
    alias: "authPasswordResetValidate",
    description: `Verify that password reset is still valid`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: authPasswordReset_Body,
      },
      {
        name: "resetID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ isValid: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/auth/refresh",
    alias: "authRefresh",
    description: `Generate new short-lived JWT for current session user`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/auth/signin",
    alias: "authLogin",
    description: `Login with email &amp; password`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: authLogin_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/auth/signout",
    alias: "authSignout",
    description: `Signout and forget session and refresh tokens`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/auth/token-auth",
    alias: "authEmailTokenAuth",
    description: `Start authentication with token for given email`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: authEmailTokenAuth_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acveID: z.string(),
                createdAt: z.string(),
                type: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/auth/token-signin",
    alias: "authEmailTokenSignin",
    description: `Authentication with token for given email`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: authEmailTokenSignin_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/auth/validate-email",
    alias: "authValidateEmail",
    description: `checks if the email is valid for appl_id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: authEmailTokenAuth_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                email: z.string(),
                isValid: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/document-templates/:doteID",
    alias: "documentTemplateGet",
    description: `Get one document template`,
    requestFormat: "json",
    parameters: [
      {
        name: "doteID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                defaultData: z.unknown(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                doteID: z.string(),
                formSchema: z.unknown(),
                permissionMode: z.string(),
                printSchema: z.unknown(),
                title: z.string(),
                uiSchema: z.unknown(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/documents",
    alias: "documentCreate",
    description: `Create one document`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: documentCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                data: z.unknown(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                docuID: z.string(),
                doteID: z.string(),
                formSchema: z.unknown(),
                permissionMode: z.string(),
                printSchema: z.unknown(),
                title: z.string(),
                uiSchema: z.unknown(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/onboarding/create-account",
    alias: "authCreateAccount",
    description: `Create a new account`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: authCreateAccount_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/public-auth-media",
    alias: "mideaServerPublicAuth",
    description: `Public authenticate media server access`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ jwt: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/search/geo-locations",
    alias: "geoLocationList",
    description: `Return a search of GeoLocationView`,
    requestFormat: "json",
    parameters: [
      {
        name: "search",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "cep",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_GeoLocationView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/survey/:slug",
    alias: "surveyGet",
    description: `Get one survey`,
    requestFormat: "json",
    parameters: [
      {
        name: "slug",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                activeAt: z.string().nullish(),
                allowedUsers: z.array(z.string()),
                answers: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                descriptionFinished: z.string(),
                displayOptions: z.unknown(),
                drafts: z.number(),
                slug: z.string(),
                survID: z.string(),
                surveyForms: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SurveyForm
                ),
                title: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/survey/response",
    alias: "surveyResponse",
    description: `answer survey`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: surveyResponse_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                clientDevice: z.string(),
                clientIP: z.string(),
                clientUID: z.string(),
                createdAt: z.string(),
                docuID: z.string(),
                doteID: z.string(),
                metadata: z.unknown(),
                sureID: z.number(),
                sursID: z.string(),
                survID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/survey/session",
    alias: "surveyCreateSession",
    description: `Create new survey session`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ survID: z.string() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                submittedAt: z.string().nullish(),
                sursID: z.string(),
                survID: z.string(),
                userID: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/survey/submit",
    alias: "surveySubmit",
    description: `submit response`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ sursID: z.string() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/term/:slug",
    alias: "termGet",
    description: `Get one term`,
    requestFormat: "json",
    parameters: [
      {
        name: "slug",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                slug: z.string(),
                termID: z.number(),
                title: z.string(),
                type: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/term/accept",
    alias: "termAccept",
    description: `Accept a term`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ termID: z.number() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                accepted: z.boolean(),
                termID: z.number(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/term/pending",
    alias: "termGetPending",
    description: `get all pending terms`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                terms: z.array(
                  github_com_falqondev_PMSPDS_backend_service_PendingTerm
                ),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/users/:userID",
    alias: "usersGetUserByID",
    description: `Return the authenticated user&#x27;s data`,
    requestFormat: "json",
    parameters: [
      {
        name: "userID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "patch",
    path: "/api/users/:userID",
    alias: "usersPatchUserByID",
    description: `Update user&#x27;s data`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: usersGetProfileMe_Body,
      },
      {
        name: "userID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                inusID: z.number().nullish(),
                name: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/users/profile/:userID",
    alias: "usersGetProfileMe",
    description: `Get user profile`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: usersGetProfileMe_Body,
      },
      {
        name: "userID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                inusID: z.number().nullish(),
                name: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
