import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { createDeviasTheme } from './themes/devias/theme/create-theme';

export interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps): JSX.Element {
  const theme = createDeviasTheme({
    colorScheme: 'light',
    primaryColor: 'neonBlue',
  });

  return (
    <CssVarsProvider
      defaultColorScheme={'light'}
      defaultMode={'light'}
      theme={theme}
    >
      <CssBaseline />
      {children}
    </CssVarsProvider>
  );
}
