import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { UserMe, UserInformation } from '@pmspds/core-lib/src/api/types';

type InitialState = {
  userProfile?: UserMe;
  userByUserID: { [userID: string]: UserInformation | undefined };
  userListed: string[];
  userByinstID: { [instID: string]: string[] | undefined };
};

const initialData: InitialState = {
  userProfile: undefined,
  userByUserID: {},
  userListed: [],
  userByinstID: {},
};

let store = createStore<InitialState>(() => initialData);

export const setUserProfile = (u?: UserMe | ((u?: UserMe) => UserMe)) => {
  if (typeof u === 'function') {
    const { userProfile } = store.getState();
    store.setState({ userProfile: u(userProfile) });
    return;
  }

  store.setState({ userProfile: u });
};

export const setUser = (
  u:
    | UserInformation
    | ((p: {
        [userID: string]: UserInformation | undefined;
      }) => UserInformation)
) => {
  store.setState((state) => {
    const { userByUserID } = state;
    let userByinstID = state.userByinstID;

    const userToUpdate = typeof u === 'function' ? u(userByUserID) : u;
    (userToUpdate?.userInstitutions ?? []).forEach((user) => {
      const instID = user?.instID;
      if (instID) {
        userByinstID[instID] = [
          ...(userByinstID?.[instID] ?? []),
          userToUpdate.userID,
        ];
      }
    });

    return {
      userByUserID: {
        ...userByUserID,
        [userToUpdate.userID]: userToUpdate,
      },
      userByinstID,
    };
  });
};

export const removeUser = (u: UserInformation) => {
  let userByUserID = store.getState().userByUserID;
  delete userByUserID[u.userID];
  store.setState({ userByUserID });
};

export const setUsers = (
  users: UserInformation[] | ((p: UserInformation[]) => UserInformation[])
) => {
  store.setState((state) => {
    let list: UserInformation[] = [];
    let userByUserID = state.userByUserID;
    let userByinstID = state.userByinstID;

    if (Array.isArray(users)) {
      list = users;
    } else {
      const current = Object.keys(userByUserID)
        .map((userID) => userByUserID[userID])
        .filter((c) => !!c) as UserInformation[];
      list = users(current);
    }
    const userListed = list.map((c) => {
      userByUserID[c.userID] = c;
      (c?.userInstitutions ?? []).forEach((user) => {
        const instID = user?.instID;
        if (instID) {
          userByinstID[instID] = [...(userByinstID?.[instID] ?? []), c.userID];
        }
      });

      return c.userID;
    });
    return {
      userByUserID,
      userListed,
      userByinstID,
    };
  });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function reset(
  newValue?: (current: InitialState) => Partial<InitialState>
) {
  const current = store.getState();
  store.setState({ ...initialData, ...(newValue?.(current) ?? {}) });
}

export function state() {
  return store.getState();
}

export const useUserStore = () => useStore(store, (s) => s);
