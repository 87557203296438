import { createApiClient } from './zodios/ecommerce';
import { createApiClient as createMediaApiClient } from './zodios/media';
import { createApiClient as createAdminApiClient } from './zodios/admin';
import { eraseCookie, getCookie, setCookie } from '../cookie';
import { pluginToken } from './plugins/pluginToken';
import {
  setAuthSession,
  setMediaToken,
  COOKIE_TOKEN_MEDIA,
  COOKIE_TOKEN_API,
  COOKIE_EXPIRY,
} from '../repo/session';
import { resetRepositories } from '../repo';
import {
  SettingsKeys,
  setLocalConfig,
  getLocalConfig,
} from '@pmspds/ui-lib/hooks/localConfig';

export { schemas } from './zodios/ecommerce';
export { schemas as adminSchemas } from './zodios/admin';

function newApiClient() {
  if (typeof window !== 'undefined') {
    return createApiClient('/');
  }
  const env = process.env.NODE_ENV;
  let baseUrl = process.env.API_PATH;
  if (!baseUrl) {
    baseUrl =
      env == 'development'
        ? 'http://localhost:8089'
        : 'https://next.pmspdsstock.net';
  }
  return createApiClient(baseUrl);
}

function newMediaApiClient() {
  const env = process.env.NODE_ENV;
  let baseUrl = process.env.MEDIA_API_PATH;
  if (!baseUrl) {
    baseUrl =
      env == 'development'
        ? 'http://localhost:8090'
        : 'https://media.pmspdsstock.net';
  }
  return createMediaApiClient(baseUrl);
}

function newAdminApiClient() {
  const env = process.env.NODE_ENV;
  let baseUrl = process.env.ADMIN_API_PATH;
  if (!baseUrl) {
    baseUrl =
      env == 'development'
        ? 'http://localhost:8089'
        : 'https://admin.pmspdsstock.net';
  }
  return createAdminApiClient(baseUrl);
}

const api = newApiClient();
const mediaApi = newMediaApiClient();
const adminApi = newAdminApiClient();

let uploadedMediaAuth = 'ecommerce';

export function useAdminMediaAuth() {
  uploadedMediaAuth = 'admin';
}

const after: ReturnType<typeof pluginToken> = {
  name: 'after',

  request: async (api, config) => {
    const conf = { ...config, withCredentials: true };
    return conf;
  },

  response: async (api, config, response) => {
    try {
      const {
        data: {
          item: { jwt, user, roles },
        },
      } = response.data;
      setCookie(
        COOKIE_TOKEN_API,
        jwt,
        new Date(new Date().getTime() + COOKIE_EXPIRY)
      );
      setAuthSession(jwt, user, roles);
    } catch (e) {
      console.warn(e);
    }
    return response;
  },
};

const afterLogout: ReturnType<typeof pluginToken> = {
  name: 'afterLogout',

  response: async (api, config, response) => {
    try {
      eraseCookie(COOKIE_TOKEN_API);
      eraseCookie(COOKIE_TOKEN_MEDIA);
      setAuthSession('', undefined, []);
      resetRepositories();
    } catch (e) {
      console.warn(e);
    }
    return response;
  },
};

const mediaAfter: ReturnType<typeof pluginToken> = {
  name: 'after',

  request: async (api, config) => config,

  response: async (api, config, response) => {
    try {
      const jwt = response.data.data.item.jwt;
      setCookie(
        COOKIE_TOKEN_MEDIA,
        jwt,
        new Date(new Date().getTime() + COOKIE_EXPIRY)
      );
      setMediaToken(jwt);
    } catch (e) {
      console.warn(e);
    }
    return response;
  },
};

const afterCreateSurveySession: ReturnType<typeof pluginToken> = {
  name: 'after',

  request: async (api, config) => config,

  response: async (api, config, response) => {
    try {
      const {
        data: {
          item: { sursID, survID },
        },
      } = response.data;
      const currentValue = getLocalConfig(SettingsKeys.SurveySessionID);
      setLocalConfig(SettingsKeys.SurveySessionID, {
        ...currentValue?.value,
        [survID]: sursID,
      });
    } catch (e) {
      console.warn(e);
    }
    return response;
  },
};

if (typeof window !== 'undefined') {
  api.use('post', '/api/onboarding/create-account', after);
  api.use('post', '/api/auth/signin', after);
  api.use('get', '/api/auth/refresh', after);
  api.use('post', '/api/auth/signout', afterLogout);
  api.use('post', '/api/auth/one-time-login', after);
  api.use('post', '/api/survey/session', afterCreateSurveySession);
  api.use(
    pluginToken({
      getToken: async () => getCookie(COOKIE_TOKEN_API) ?? undefined,
      renewToken: async () => {
        const {
          data: {
            item: { jwt },
          },
        } = await api.get('/api/auth/refresh');
        return jwt;
      },
    })
  );
  adminApi.use(
    pluginToken({
      getToken: async () => getCookie(COOKIE_TOKEN_API) ?? undefined,
      renewToken: async () => {
        const {
          data: {
            item: { jwt },
          },
        } = await api.get('/api/auth/refresh');
        return jwt;
      },
    })
  );

  api.use('post', '/api/auth-media', mediaAfter);
  adminApi.use('post', '/api/auth-media', mediaAfter);
  mediaApi.use(
    pluginToken({
      getToken: async () => getCookie(COOKIE_TOKEN_MEDIA) ?? undefined,
      renewToken: async () => {
        const auth =
          uploadedMediaAuth === 'admin'
            ? adminApi.mediaAuth
            : api.uploadedMediaAuth;
        const {
          data: {
            item: { jwt },
          },
        } = await auth({});
        return jwt;
      },
    })
  );
}

function initSession() {
  api.authRefresh().catch((e) => {
    console.warn(e?.message);
  });
  setInterval(() => {
    api.authRefresh().catch((e) => {
      console.warn(e?.message);
    });
  }, COOKIE_EXPIRY - 5000);
}

initSession();

export { api, mediaApi, adminApi };
