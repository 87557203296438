import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Loader from '@pmspds/core-lib/components/Loader';
import PageLoader from '@pmspds/ui-lib/base/loader/PageLoader';
import Logout from '@/containers/Auth/Logout';
// dirty fix for type error
const Router: any = BrowserRouter;

const Admin = Loader({
  loader: () => import('./admin'),
  fallback: PageLoader,
});

const Auth = Loader({
  loader: () => import('./auth'),
  fallback: PageLoader,
});

const Public = Loader({
  loader: () => import('./Public'),
  fallback: PageLoader,
});

function App() {
  return (
    <Router>
      <>
        <Switch>
          <Route exact path="/">
            <Redirect to="/admin" />
          </Route>
          <Route exact path="/admin/signout" component={Logout} />
          <Route path="/auth" component={Auth} />
          <Route path="/admin" component={Admin} />
          <Route path="/public" component={Public} />
        </Switch>
      </>
    </Router>
  );
}

export default App;
