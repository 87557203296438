import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const github_com_falqondev_PMSPDS_backend_service_ActionPlanUserView = z
  .object({
    instID: z.string(),
    inusID: z.number(),
    userAvatar: z.string().nullish(),
    userEmail: z.string().nullish(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ActionPlanView = z
  .object({
    acplID: z.string(),
    actionCount: z.number(),
    actionPlanUsers: z.array(
      github_com_falqondev_PMSPDS_backend_service_ActionPlanUserView
    ),
    attachment: z.string().nullish(),
    budgets: z.array(
      z
        .object({
          actual: z.number(),
          budgID: z.string(),
          busoID: z.number(),
          predicted: z.number(),
          received: z.number(),
        })
        .passthrough()
    ),
    comments: z.number().nullish(),
    content: z.string(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    institutionUserAvatar: z.string().nullish(),
    institutionUserEmail: z.string().nullish(),
    institutionUserName: z.string(),
    institutions: z.array(z.string()),
    inusID: z.number(),
    name: z.string(),
    period: z
      .object({ endAt: z.string(), oppeID: z.number(), startAt: z.string() })
      .passthrough(),
    projID: z.string(),
    projectInfo: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    status: z.string(),
    thematicAxisInfo: z.array(
      z
        .object({
          displayOptions: z
            .object({ color: z.string() })
            .passthrough()
            .nullish(),
          thaxID: z.number(),
        })
        .passthrough()
    ),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ActionPlanBudgetForm = z
  .object({
    actual: z.number(),
    busoID: z.number(),
    predicted: z.number(),
    received: z.number(),
  })
  .passthrough();
const actionPlanCreate_Body = z
  .object({
    actionPlanUsers: z.array(z.number()),
    attachment: z.string().nullish(),
    budgets: z.array(
      github_com_falqondev_PMSPDS_backend_service_ActionPlanBudgetForm
    ),
    content: z.string(),
    description: z.string(),
    institutions: z.array(z.string()),
    inusID: z.number(),
    name: z.string(),
    period: z.object({ endAt: z.string(), startAt: z.string() }).passthrough(),
    projID: z.string(),
    status: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ActionPlanCommentView = z
  .object({
    acplID: z.string(),
    attachment: z.string().nullish(),
    commID: z.number(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    message: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const actionPlanCommentCreate_Body = z
  .object({ attachment: z.string().nullish(), message: z.string() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ActionPlanBudgetHistory = z
  .object({
    acplID: z.string(),
    updatedAt: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ActionPlanPeriodHistory = z
  .object({
    acplID: z.string(),
    createdAt: z.string(),
    endAt: z.string(),
    oppeID: z.number(),
    startAt: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ActionPlanStatusHistory = z
  .object({
    acplID: z.string(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    message: z.string(),
    status: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ActionCommentView = z
  .object({
    actiID: z.string(),
    attachment: z.string().nullish(),
    commID: z.number(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    message: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ActionView = z
  .object({
    acplID: z.string(),
    actiID: z.string(),
    comments: z.number().nullish(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string(),
    inusID: z.number(),
    name: z.string(),
    period: z
      .object({ endAt: z.string(), oppeID: z.number(), startAt: z.string() })
      .passthrough(),
    projectInfo: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    status: z.string(),
    statusAttachment: z.string().nullish(),
    statusMessage: z.string(),
    userAvatar: z.string().nullish(),
    userEmail: z.string().nullish(),
    userName: z.string().nullish(),
  })
  .passthrough();
const actionCreate_Body = z
  .object({
    acplID: z.string(),
    instID: z.string().nullish(),
    inusID: z.number(),
    name: z.string(),
    period: z.object({ endAt: z.string(), startAt: z.string() }).passthrough(),
    status: z.string(),
    statusAttachment: z.string().nullish(),
    statusMessage: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ActionPeriodHistory = z
  .object({
    acpeID: z.number(),
    actiID: z.string(),
    createdAt: z.string(),
    endAt: z.string(),
    oppeID: z.number(),
    startAt: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ActionStatusHistory = z
  .object({
    actiID: z.string(),
    attachment: z.string().nullish(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    message: z.string(),
    status: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ApplicationRole = z
  .object({
    aproID: z.string(),
    description: z.string(),
    name: z.string(),
    roles: z.array(z.string()),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_AuditLogView = z
  .object({
    action: z.string(),
    auloID: z.number(),
    createdAt: z.string(),
    createdBy: z.string(),
    resourceMetadata: z.unknown(),
    resourceName: z.string(),
    userInfo: z
      .object({
        avatar: z.string().nullable(),
        email: z.string().nullable(),
        name: z.string().nullable(),
      })
      .partial()
      .passthrough(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_BudgetSource = z
  .object({
    busoID: z.number(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_Rule = z
  .object({
    calc: z.string(),
    cukpID: z.number().nullish(),
    displayOption: z.string(),
    kpi: z.string(),
    name: z.string(),
    numValue: z.number(),
    operation: z.string(),
    referenceDate: z.string().nullish(),
    source: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_BusinessMetricView = z
  .object({
    bumeID: z.number(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    kpiProgress: z
      .object({
        actual: z.number(),
        displayOption: z.string(),
        offTrack: z.number(),
        onTrack: z.number(),
        referenceDate: z.string().nullish(),
        referenceValue: z.number(),
      })
      .passthrough(),
    name: z.string(),
    planID: z.string(),
    progID: z.string().nullish(),
    projID: z.string().nullish(),
    projectInfo: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    rule: z.array(github_com_falqondev_PMSPDS_backend_service_Rule),
    status: z.string(),
    thematicAxisInfo: z.array(
      z
        .object({
          displayOptions: z
            .object({ color: z.string() })
            .passthrough()
            .nullish(),
          thaxID: z.number(),
        })
        .passthrough()
    ),
  })
  .passthrough();
const businessMetricCreate_Body = z
  .object({
    description: z.string(),
    name: z.string(),
    planID: z.string(),
    progID: z.string().nullish(),
    projID: z.string().nullish(),
    rule: z.array(github_com_falqondev_PMSPDS_backend_service_Rule),
    status: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_KPIValue = z
  .object({ targetAt: z.string(), value: z.number() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_CustomKPIView = z
  .object({
    createdAt: z.string(),
    cukpID: z.number(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    name: z.string(),
    updatedAt: z.string(),
    values: z.array(github_com_falqondev_PMSPDS_backend_service_KPIValue),
  })
  .passthrough();
const customKPICreate_Body = z
  .object({
    description: z.string(),
    name: z.string(),
    values: z.array(github_com_falqondev_PMSPDS_backend_service_KPIValue),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DashboardIncidentsByNature = z
  .object({
    innaID: z.number(),
    natureName: z.string(),
    securityIncidents: z
      .object({ inciIDs: z.array(z.string()), total: z.number() })
      .passthrough(),
    serviceIncidents: z
      .object({ inciIDs: z.array(z.string()), total: z.number() })
      .passthrough(),
    total: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DashboardIncidentNeighborhood =
  z
    .object({
      neighborhood: z.string(),
      securityIncidents: z
        .object({ inciIDs: z.array(z.string()), total: z.number() })
        .passthrough(),
      serviceIncidents: z
        .object({ inciIDs: z.array(z.string()), total: z.number() })
        .passthrough(),
      total: z.number(),
    })
    .passthrough();
const github_com_falqondev_PMSPDS_backend_service_BudgetSummary = z
  .object({
    axisName: z.string(),
    sumActual: z.number(),
    sumPredicted: z.number(),
    sumReceived: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_PlanLatestComments = z
  .object({
    acplID: z.string(),
    actiID: z.string().nullish(),
    avatar: z.string().nullish(),
    createdAt: z.string(),
    message: z.string(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_PlanNextTask = z
  .object({
    acplID: z.string(),
    actiID: z.string(),
    finishDate: z.string(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_Resource = z
  .object({ name: z.string(), totalCount: z.number() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ResourceCount = z
  .object({
    createdDate: z.string().nullish(),
    resourceCount: z.number(),
    resourceType: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DashboardSurveyResponse = z
  .object({ answers: z.number(), date: z.string().nullish() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_WorkOrderByProgram = z
  .object({
    status: z.string(),
    total: z.number(),
    woorIDs: z.array(z.string()),
    wostID: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DashboardWorkOrderByProgram =
  z
    .object({
      programName: z.string(),
      total: z.number(),
      woprID: z.number(),
      workOrders: z.array(
        github_com_falqondev_PMSPDS_backend_service_WorkOrderByProgram
      ),
    })
    .passthrough();
const github_com_falqondev_PMSPDS_backend_service_WorkOrderByStatus = z
  .object({ date: z.string(), total: z.number() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DashboardWorkOrderByStatus = z
  .object({
    status: z.string(),
    statusInfo: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    total: z.number(),
    workOrders: z.array(
      github_com_falqondev_PMSPDS_backend_service_WorkOrderByStatus
    ),
    wostID: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DefaultDescriptionTextView = z
  .object({
    createdAt: z.string(),
    ddteID: z.number(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    formField: z.string(),
    formName: z.string(),
    instID: z.string(),
    institutionName: z.string(),
    name: z.string(),
    placeholder: z.boolean(),
  })
  .passthrough();
const defaultDescriptionTextCreate_Body = z
  .object({
    description: z.string(),
    formField: z.string(),
    formName: z.string(),
    instID: z.string(),
    name: z.string(),
    placeholder: z.boolean(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DispatchNatureView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    dinaID: z.number(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const dispatchNatureCreate_Body = z
  .object({ instID: z.string().nullish(), name: z.string() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DispatchStatusView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    distID: z.number(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DispatchSubnatureView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    dinaID: z.number(),
    disuID: z.number(),
    name: z.string(),
  })
  .passthrough();
const dispatchSubnatureCreate_Body = z
  .object({ dinaID: z.number(), name: z.string() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DispatchUnitView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    diunID: z.number(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DocumentTemplate = z
  .object({
    createdAt: z.string(),
    defaultData: z.unknown(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    doteID: z.string(),
    formSchema: z.unknown(),
    permissionMode: z.string(),
    printSchema: z.unknown(),
    title: z.string(),
    uiSchema: z.unknown(),
    updatedAt: z.string(),
  })
  .passthrough();
const documentTemplateCreate_Body = z
  .object({
    defaultData: z.unknown(),
    description: z.string(),
    formSchema: z.unknown(),
    permissionMode: z.string(),
    printSchema: z.unknown(),
    title: z.string(),
    uiSchema: z.unknown(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DocumentationView = z
  .object({
    content: z.string(),
    createdAt: z.string(),
    docuID: z.number(),
    tags: z.array(z.string()),
    title: z.string(),
    videoURL: z.string(),
  })
  .passthrough();
const documentationCreate_Body = z
  .object({
    content: z.string(),
    tags: z.array(z.string()).nullish(),
    title: z.string(),
    videoURL: z.string().nullish(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_Document = z
  .object({
    createdAt: z.string(),
    data: z.unknown(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    docuID: z.string(),
    doteID: z.string(),
    formSchema: z.unknown(),
    permissionMode: z.string(),
    printSchema: z.unknown(),
    title: z.string(),
    uiSchema: z.unknown(),
    updatedAt: z.string(),
  })
  .passthrough();
const documentCreate_Body = z
  .object({
    data: z.unknown(),
    description: z.string(),
    doteID: z.string(),
    formSchema: z.unknown(),
    permissionMode: z.string(),
    printSchema: z.unknown(),
    title: z.string(),
    uiSchema: z.unknown(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_FleetVehicleView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    details: z
      .object({
        carrying_capacity: z.string(),
        color: z.string(),
        dimensions: z
          .object({ height: z.number(), length: z.number(), width: z.number() })
          .passthrough(),
        engine: z
          .object({ capacity: z.string(), type: z.string() })
          .passthrough(),
        features: z.array(z.string()),
        mileage_km: z.number(),
        purchase_date: z.string(),
        seat_numbers: z.number(),
        serial_number: z.string(),
        type: z.string(),
        warranty: z
          .object({ details: z.string().nullish(), expiry_date: z.string() })
          .passthrough(),
      })
      .passthrough(),
    flveID: z.number(),
    instID: z.string(),
    institutionName: z.string().nullish(),
    licensePlate: z.string(),
    make: z.string(),
    model: z.string(),
    registrationNumber: z.string(),
    updatedAt: z.string(),
    year: z.number(),
  })
  .passthrough();
const fleetVehicleCreate_Body = z
  .object({
    details: z
      .object({
        carrying_capacity: z.string(),
        color: z.string(),
        dimensions: z
          .object({ height: z.number(), length: z.number(), width: z.number() })
          .passthrough(),
        engine: z
          .object({ capacity: z.string(), type: z.string() })
          .passthrough(),
        features: z.array(z.string()),
        mileage_km: z.number(),
        purchase_date: z.string(),
        seat_numbers: z.number(),
        serial_number: z.string(),
        type: z.string(),
        warranty: z
          .object({ details: z.string().nullish(), expiry_date: z.string() })
          .passthrough(),
      })
      .passthrough(),
    instID: z.string(),
    licensePlate: z.string(),
    make: z.string(),
    model: z.string(),
    registrationNumber: z.string(),
    year: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_FormDocumentTemplateView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    doteID: z.string(),
    fodtID: z.number(),
    formName: z.string(),
    instID: z.string(),
    institutionName: z.string(),
  })
  .passthrough();
const formDocumentTemplateCreate_Body = z
  .object({
    documentTemplate: z
      .object({
        defaultData: z.unknown(),
        description: z.string(),
        formSchema: z.unknown(),
        permissionMode: z.string(),
        printSchema: z.unknown(),
        title: z.string(),
        uiSchema: z.unknown(),
      })
      .passthrough(),
    formName: z.string(),
    instID: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_FormValidationView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    formName: z.string(),
    fovaID: z.number(),
    instID: z.string(),
    institutionName: z.string(),
    validationSchema: z.unknown(),
  })
  .passthrough();
const formValidationCreate_Body = z
  .object({
    formName: z.string(),
    instID: z.string(),
    validationSchema: z.unknown(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_GuicheView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    guicID: z.number(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentActionTypeView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    iatyID: z.number(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentDispatcherView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    indiID: z.number(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentEnvironmentView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    inenID: z.number(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentEstablishmentView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    inesID: z.number(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentNatureView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    innaID: z.number(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentOriginView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    inorID: z.number(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentProgramView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    inprID: z.number(),
    instID: z.string(),
    institutionName: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const incidentProgramCreate_Body = z
  .object({ instID: z.string(), name: z.string() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentResponseLocationView =
  z
    .object({
      createdAt: z.string(),
      deletedAt: z.string().nullish(),
      instID: z.string().nullish(),
      institutionName: z.string().nullish(),
      irloID: z.number(),
      name: z.string(),
    })
    .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentStatus = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    info: z
      .object({
        displayOptions: z
          .object({ color: z.string().nullable() })
          .partial()
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    istaID: z.number(),
    name: z.string(),
    reasons: z.array(z.string()).nullish(),
    requiredReason: z.boolean().nullish(),
  })
  .passthrough();
const incidentStatusCreate_Body = z
  .object({
    info: z
      .object({
        displayOptions: z
          .object({ color: z.string().nullable() })
          .partial()
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    name: z.string(),
    reasons: z.array(z.string()).nullish(),
    requiredReason: z.boolean().nullish(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentSubnatureView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    inbnID: z.number(),
    innaID: z.number(),
    name: z.string(),
  })
  .passthrough();
const incidentSubnatureCreate_Body = z
  .object({ innaID: z.number(), name: z.string() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentSubprogramView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    inprID: z.number(),
    insuID: z.number(),
    name: z.string(),
  })
  .passthrough();
const incidentSubprogramCreate_Body = z
  .object({ inprID: z.number(), name: z.string() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_Depredation = z
  .object({
    costEstimate: z.string(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    deprID: z.number(),
    level: z.string(),
    owner: z.string(),
    type: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentVehicleView = z
  .object({
    departedAt: z.string().nullish(),
    flveID: z.number(),
    ifdiID: z.number(),
    inveID: z.number(),
    licensePlate: z.string(),
    model: z.string(),
    responsibleName: z.string(),
    returnedAt: z.string().nullish(),
    userID: z.string(),
    year: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_InvolvedPartyView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    documentNumber: z.string(),
    documentType: z.string(),
    educationLevel: z.string().nullish(),
    estimatedAge: z.number().nullish(),
    incidentResponseLocation: z.string(),
    income: z.string().nullish(),
    inpaID: z.number(),
    involvementType: z.string(),
    irloID: z.number().nullish(),
    maritalStatus: z.string().nullish(),
    name: z.string().nullish(),
    phcoID: z.number().nullish(),
    physicalCondition: z.string(),
    professionalActivity: z.string().nullish(),
    religion: z.string().nullish(),
    sex: z.string(),
    skinTone: z.string(),
    vulnerablePopulation: z.string(),
    vupoID: z.number().nullish(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_SeizedItemView = z
  .object({
    amount: z.number(),
    attachment: z.string().nullish(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    estimatedValue: z.number().nullish(),
    name: z.string(),
    sealNumber: z.string().nullish(),
    seitID: z.number(),
    storageLocation: z.string(),
    unitOfMeasure: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentView = z
  .object({
    actionTypeName: z.string(),
    address: z
      .object({
        city: z.string().nullish(),
        complement: z.string().nullish(),
        isVillage: z.boolean(),
        latitude: z.number().nullish(),
        longitude: z.number().nullish(),
        neighborhood: z.string().nullish(),
        number: z.string().nullish(),
        state: z.string().nullish(),
        street: z.string().nullish(),
        zipCode: z.string().nullish(),
      })
      .passthrough()
      .nullish(),
    agentID: z.string().nullish(),
    agentName: z.string(),
    agentRole: z.string().nullish(),
    arrivalAt: z.string().nullish(),
    buunID: z.number().nullish(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    depredations: z.array(
      github_com_falqondev_PMSPDS_backend_service_Depredation
    ),
    description: z.string(),
    details: z
      .object({
        confidential: z.boolean().nullable(),
        protocolNumber: z.string().nullable(),
        relatorAttachment: z.string().nullable(),
        relatorDescription: z.string().nullable(),
        solution: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    dinaID: z.number().nullish(),
    dispatchNature: z.string(),
    dispatchStatus: z.string(),
    dispatchSubnature: z.string(),
    dispatchUnit: z.string(),
    dispatcherName: z.string(),
    distID: z.number().nullish(),
    disuID: z.number().nullish(),
    diunID: z.number().nullish(),
    environmentName: z.string().nullish(),
    establishmentName: z.string().nullish(),
    fieldContact: z
      .object({ name: z.string().nullable(), phone: z.string().nullable() })
      .partial()
      .passthrough(),
    guicID: z.number().nullish(),
    guicheName: z.string(),
    iatyID: z.number().nullish(),
    ifdiID: z.number().nullish(),
    inbnID: z.number().nullish(),
    inciID: z.string(),
    incidentType: z.string(),
    incidentVehicles: z.array(
      github_com_falqondev_PMSPDS_backend_service_IncidentVehicleView
    ),
    indeID: z.number().nullish(),
    indiID: z.number().nullish(),
    inenID: z.number().nullish(),
    inesID: z.number().nullish(),
    infdID: z.number().nullish(),
    ininID: z.number().nullish(),
    innaID: z.number().nullish(),
    inorID: z.number().nullish(),
    inprID: z.number().nullish(),
    inseID: z.number().nullish(),
    instID: z.string(),
    institutionName: z.string(),
    insuID: z.number().nullish(),
    involvedParties: z.array(
      github_com_falqondev_PMSPDS_backend_service_InvolvedPartyView
    ),
    istaID: z.number(),
    natureName: z.string(),
    originName: z.string(),
    partnerInstID: z.string().nullish(),
    partnerInstitutionName: z.string(),
    programName: z.string(),
    progressionFromUid: z.string().nullish(),
    publicEquipmentName: z.string(),
    pueqID: z.number().nullish(),
    relatorCode: z.string().nullish(),
    relatorID: z.string().nullish(),
    relatorName: z.string(),
    relatorRole: z.string().nullish(),
    requester: z
      .object({
        email: z.string().nullable(),
        name: z.string().nullable(),
        phone: z.string().nullable(),
        sex: z.string().nullable(),
      })
      .partial()
      .passthrough()
      .nullish(),
    sechID: z.number().nullish(),
    seizedItems: z.array(
      github_com_falqondev_PMSPDS_backend_service_SeizedItemView
    ),
    serviceChannelName: z.string(),
    servicedAt: z.string().nullish(),
    statusInfo: z
      .object({
        displayOptions: z
          .object({ color: z.string().nullable() })
          .partial()
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    statusName: z.string(),
    statusReason: z.string().nullish(),
    subnatureName: z.string(),
    subprogramName: z.string(),
    uid: z.string(),
    updatedAt: z.string(),
    userID: z.string(),
    workOrderUid: z.string().nullish(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DepredationForm = z
  .object({
    costEstimate: z.string(),
    level: z.string(),
    owner: z.string(),
    type: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_DocumentForm = z
  .object({
    data: z.unknown(),
    description: z.string(),
    doteID: z.string(),
    formSchema: z.unknown(),
    permissionMode: z.string(),
    printSchema: z.unknown(),
    title: z.string(),
    uiSchema: z.unknown(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentVehicleForm = z
  .object({
    departedAt: z.string().nullish(),
    flveID: z.number(),
    returnedAt: z.string().nullish(),
    userID: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_InvolvedPartyForm = z
  .object({
    description: z.string(),
    documentNumber: z.string().nullish(),
    documentType: z.string().nullish(),
    educationLevel: z.string().nullish(),
    estimatedAge: z.number().nullish(),
    income: z.string().nullish(),
    involvementType: z.string(),
    irloID: z.number().nullish(),
    maritalStatus: z.string().nullish(),
    name: z.string().nullish(),
    phcoID: z.number().nullish(),
    professionalActivity: z.string().nullish(),
    religion: z.string().nullish(),
    sex: z.string(),
    skinTone: z.string(),
    vupoID: z.number().nullish(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_SeizedItemForm = z
  .object({
    amount: z.number(),
    attachment: z.string().nullish(),
    description: z.string(),
    estimatedValue: z.number().nullish(),
    name: z.string(),
    sealNumber: z.string().nullish(),
    storageLocation: z.string(),
    unitOfMeasure: z.string(),
  })
  .passthrough();
const incidentCreate_Body = z
  .object({
    address: z
      .object({
        city: z.string().nullish(),
        complement: z.string().nullish(),
        isVillage: z.boolean(),
        latitude: z.number().nullish(),
        longitude: z.number().nullish(),
        neighborhood: z.string().nullish(),
        number: z.string().nullish(),
        state: z.string().nullish(),
        street: z.string().nullish(),
        zipCode: z.string().nullish(),
      })
      .passthrough()
      .nullish(),
    agentID: z.string().nullish(),
    agentRole: z.string().nullish(),
    arrivalAt: z.string().nullish(),
    buunID: z.number().nullish(),
    depredations: z
      .array(github_com_falqondev_PMSPDS_backend_service_DepredationForm)
      .nullish(),
    description: z.string().nullish(),
    details: z
      .object({
        confidential: z.boolean().nullable(),
        protocolNumber: z.string().nullable(),
        relatorAttachment: z.string().nullable(),
        relatorDescription: z.string().nullable(),
        solution: z.string().nullable(),
      })
      .partial()
      .passthrough()
      .nullish(),
    dinaID: z.number().nullish(),
    distID: z.number().nullish(),
    disuID: z.number().nullish(),
    diunID: z.number().nullish(),
    documents: z
      .array(github_com_falqondev_PMSPDS_backend_service_DocumentForm)
      .nullish(),
    fieldContact: z
      .object({ name: z.string().nullable(), phone: z.string().nullable() })
      .partial()
      .passthrough()
      .nullish(),
    guicID: z.number().nullish(),
    iatyID: z.number().nullish(),
    inbnID: z.number().nullish(),
    incidentType: z.string(),
    incidentVehicles: z
      .array(github_com_falqondev_PMSPDS_backend_service_IncidentVehicleForm)
      .nullish(),
    indiID: z.number().nullish(),
    inenID: z.number().nullish(),
    inesID: z.number().nullish(),
    innaID: z.number().nullish(),
    inorID: z.number().nullish(),
    inprID: z.number().nullish(),
    instID: z.string(),
    insuID: z.number().nullish(),
    involvedParties: z
      .array(github_com_falqondev_PMSPDS_backend_service_InvolvedPartyForm)
      .nullish(),
    istaID: z.number(),
    partnerInstID: z.string().nullish(),
    progressionFromUid: z.string().nullish(),
    pueqID: z.number().nullish(),
    relatorID: z.string().nullish(),
    relatorRole: z.string().nullish(),
    requester: z
      .object({
        email: z.string().nullable(),
        name: z.string().nullable(),
        phone: z.string().nullable(),
        sex: z.string().nullable(),
      })
      .partial()
      .passthrough()
      .nullish(),
    sechID: z.number().nullish(),
    seizedItems: z
      .array(github_com_falqondev_PMSPDS_backend_service_SeizedItemForm)
      .nullish(),
    servicedAt: z.string().nullish(),
    statusReason: z.string().nullish(),
    workOrderUid: z.string().nullish(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_IncidentDocumentView = z
  .object({
    createdAt: z.string(),
    data: z.unknown(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    docuID: z.string(),
    doteID: z.string(),
    formSchema: z.unknown(),
    inciID: z.string(),
    infdID: z.number(),
    permissionMode: z.string(),
    printSchema: z.unknown(),
    title: z.string(),
    uiSchema: z.unknown(),
    updatedAt: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_Institution = z
  .object({
    abbreviation: z.string(),
    avatar: z.string().nullish(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    documentNumber: z.string(),
    documentType: z.string(),
    instID: z.string(),
    name: z.string(),
    updatedAt: z.string(),
  })
  .passthrough();
const institutionCreate_Body = z
  .object({
    abbreviation: z.string().nullish(),
    avatar: z.string().nullish(),
    documentNumber: z.string().nullish(),
    documentType: z.string().nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_BusinessUnit = z
  .object({
    address: z
      .object({
        city: z.string(),
        country: z.string(),
        neighborhood: z.string(),
        number: z.string(),
        state: z.string(),
        street: z.string(),
        zipCode: z.string(),
      })
      .passthrough()
      .nullish(),
    buunID: z.number(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string(),
    name: z.string(),
    updatedAt: z.string(),
  })
  .passthrough();
const institutionUnitCreate_Body = z
  .object({
    address: z
      .object({
        city: z.string(),
        country: z.string(),
        neighborhood: z.string(),
        number: z.string(),
        state: z.string(),
        street: z.string(),
        zipCode: z.string(),
      })
      .passthrough(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_InstitutionUserView = z
  .object({
    admittedAt: z.string().nullish(),
    buunID: z.number().nullish(),
    code: z.string().nullish(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string(),
    inusID: z.number(),
    name: z.string(),
    operationalCode: z.string().nullish(),
    phone: z.string(),
    sex: z.string(),
    updatedAt: z.string(),
    userID: z.string(),
  })
  .passthrough();
const institutionUserCreate_Body = z
  .object({
    admittedAt: z.string().nullish(),
    buunID: z.number().nullish(),
    code: z.string().nullish(),
    operationalCode: z.string().nullish(),
    phone: z.string(),
    sex: z.string(),
    userID: z.string(),
  })
  .passthrough();
const institutionUserUpdate_Body = z
  .object({
    admittedAt: z.string().nullish(),
    buunID: z.number().nullish(),
    code: z.string().nullish(),
    operationalCode: z.string().nullish(),
    phone: z.string(),
    sex: z.string(),
  })
  .passthrough();
const oneTimeLoginCreate_Body = z
  .object({ userID: z.string(), validUntil: z.string() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_PhysicalConditionView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
    phcoID: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProjetcUserView = z
  .object({
    instID: z.string(),
    inusID: z.number(),
    userAvatar: z.string().nullish(),
    userEmail: z.string().nullish(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_PlanView = z
  .object({
    instID: z.string(),
    inusID: z.number(),
    name: z.string(),
    planID: z.string(),
    planUsers: z.array(
      github_com_falqondev_PMSPDS_backend_service_ProjetcUserView
    ),
    thaxID: z.array(z.number()),
  })
  .passthrough();
const plansCreate_Body = z
  .object({
    inusID: z.number(),
    name: z.string(),
    planUsers: z.array(z.number()),
    thaxID: z.array(z.number()),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProgramUserView = z
  .object({
    instID: z.string(),
    inusID: z.number(),
    userAvatar: z.string().nullish(),
    userEmail: z.string().nullish(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProgramView = z
  .object({
    axes: z.array(z.number()),
    budgets: z.array(
      z
        .object({
          actual: z.number(),
          budgID: z.string(),
          busoID: z.number(),
          predicted: z.number(),
          received: z.number(),
        })
        .passthrough()
    ),
    content: z.string(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    institutionUserAvatar: z.string().nullish(),
    institutionUserEmail: z.string().nullish(),
    institutionUserName: z.string(),
    institutions: z.array(z.string()),
    inusID: z.number(),
    name: z.string(),
    period: z
      .object({ endAt: z.string(), oppeID: z.number(), startAt: z.string() })
      .passthrough(),
    planID: z.string(),
    progID: z.string(),
    programUsers: z.array(
      github_com_falqondev_PMSPDS_backend_service_ProgramUserView
    ),
    projectCount: z.number(),
    status: z.string(),
    thematicAxisInfo: z.array(
      z
        .object({
          displayOptions: z
            .object({ color: z.string() })
            .passthrough()
            .nullish(),
          thaxID: z.number(),
        })
        .passthrough()
    ),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProgramBudgetForm = z
  .object({ busoID: z.number(), predicted: z.number(), received: z.number() })
  .passthrough();
const programCreate_Body = z
  .object({
    axes: z.array(z.number()),
    budgets: z.array(
      github_com_falqondev_PMSPDS_backend_service_ProgramBudgetForm
    ),
    content: z.string(),
    description: z.string(),
    institutions: z.array(z.string()),
    inusID: z.number(),
    name: z.string(),
    period: z.object({ endAt: z.string(), startAt: z.string() }).passthrough(),
    planID: z.string(),
    programUsers: z.array(z.number()),
    status: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProgramBudgetHistory = z
  .object({
    progID: z.string(),
    updatedAt: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProgramPeriodHistory = z
  .object({
    createdAt: z.string(),
    endAt: z.string(),
    oppeID: z.number(),
    progID: z.string(),
    prpeID: z.number(),
    startAt: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProgramStatusHistory = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    message: z.string(),
    progID: z.string(),
    status: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProjectView = z
  .object({
    actionPlanCount: z.number(),
    attachment: z.string().nullish(),
    axes: z.array(z.number()),
    budgets: z.array(
      z
        .object({
          actual: z.number(),
          budgID: z.string(),
          busoID: z.number(),
          predicted: z.number(),
          received: z.number(),
        })
        .passthrough()
    ),
    content: z.string(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    info: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    institutionUserAvatar: z.string().nullish(),
    institutionUserEmail: z.string().nullish(),
    institutionUserName: z.string(),
    institutions: z.array(z.string()),
    inusID: z.number(),
    name: z.string(),
    period: z
      .object({ endAt: z.string(), oppeID: z.number(), startAt: z.string() })
      .passthrough(),
    planID: z.string(),
    progID: z.string().nullish(),
    projID: z.string(),
    projectUsers: z.array(
      github_com_falqondev_PMSPDS_backend_service_ProjetcUserView
    ),
    status: z.string(),
    thematicAxisInfo: z.array(
      z
        .object({
          displayOptions: z
            .object({ color: z.string() })
            .passthrough()
            .nullish(),
          thaxID: z.number(),
        })
        .passthrough()
    ),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProjectBudgetForm = z
  .object({ busoID: z.number(), predicted: z.number(), received: z.number() })
  .passthrough();
const projectCreate_Body = z
  .object({
    attachment: z.string().nullish(),
    axes: z.array(z.number()),
    budgets: z.array(
      github_com_falqondev_PMSPDS_backend_service_ProjectBudgetForm
    ),
    content: z.string(),
    description: z.string(),
    info: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough()
      .nullish(),
    institutions: z.array(z.string()),
    inusID: z.number(),
    name: z.string(),
    period: z.object({ endAt: z.string(), startAt: z.string() }).passthrough(),
    planID: z.string(),
    progID: z.string().nullish(),
    projectUsers: z.array(z.number()),
    status: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProjectBudgetHistory = z
  .object({
    projID: z.string(),
    updatedAt: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProjectPeriodHistory = z
  .object({
    createdAt: z.string(),
    endAt: z.string(),
    oppeID: z.number(),
    projID: z.string(),
    startAt: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ProjectStatusHistory = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    message: z.string(),
    projID: z.string(),
    status: z.string(),
    userID: z.string(),
    userName: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_PublicEquipmentView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
    pueqID: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_RequestingCompanyView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
    recoID: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ServiceChannelView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
    sechID: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_SurveyResponseData = z
  .object({
    clientDevice: z.string(),
    clientIP: z.string(),
    clientUID: z.string(),
    createdAt: z.string(),
    data: z.unknown(),
    docuID: z.string(),
    doteID: z.string(),
    formSchema: z.unknown(),
    metadata: z.unknown(),
    title: z.string(),
    uiSchema: z.unknown(),
    updatedAt: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_SurveyResponseListing = z
  .object({
    name: z.string(),
    responses: z.array(
      github_com_falqondev_PMSPDS_backend_service_SurveyResponseData
    ),
    submittedAt: z.string().nullish(),
    sursID: z.string(),
    survID: z.string(),
    updatedAt: z.string(),
  })
  .passthrough();
const surveyResponseCreate_Body = z
  .object({
    clientDevice: z.string(),
    clientIP: z.string(),
    clientUID: z.string(),
    docuID: z.string().nullish(),
    document: z
      .object({
        data: z.unknown(),
        description: z.string(),
        doteID: z.string(),
        formSchema: z.unknown(),
        permissionMode: z.string(),
        printSchema: z.unknown(),
        title: z.string(),
        uiSchema: z.unknown(),
      })
      .passthrough()
      .nullish(),
    doteID: z.string(),
    metadata: z.unknown(),
    sursID: z.string(),
    survID: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_SurveyForm = z
  .object({
    deletedAt: z.string().nullish(),
    doteID: z.string(),
    position: z.number(),
    survID: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_Survey = z
  .object({
    activeAt: z.string().nullish(),
    allowedUsers: z.array(z.string()),
    answers: z.number(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    descriptionFinished: z.string(),
    displayOptions: z.unknown(),
    drafts: z.number(),
    slug: z.string(),
    survID: z.string(),
    surveyForms: z.array(
      github_com_falqondev_PMSPDS_backend_service_SurveyForm
    ),
    title: z.string(),
    updatedAt: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_InputSurveyForm = z
  .object({ doteID: z.string(), position: z.number() })
  .passthrough();
const surveyCreate_Body = z
  .object({
    activeAt: z.string().nullish(),
    allowedUsers: z.array(z.string()),
    description: z.string().nullish(),
    descriptionFinished: z.string().nullish(),
    displayOptions: z.unknown(),
    slug: z.string().nullish(),
    surveyForms: z
      .array(github_com_falqondev_PMSPDS_backend_service_InputSurveyForm)
      .nullish(),
    title: z.string().nullish(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_MySurvey = z
  .object({
    activeAt: z.string().nullish(),
    answers: z.number(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    descriptionFinished: z.string(),
    displayOptions: z.unknown(),
    drafts: z.number(),
    slug: z.string(),
    survID: z.string(),
    surveyForms: z.array(
      github_com_falqondev_PMSPDS_backend_service_SurveyForm
    ),
    title: z.string(),
    updatedAt: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_PendingTerm = z
  .object({ slug: z.string(), termID: z.number(), title: z.string() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_Term = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    slug: z.string(),
    termID: z.number(),
    title: z.string(),
    type: z.string(),
  })
  .passthrough();
const termCreate_Body = z
  .object({
    description: z.string(),
    slug: z.string(),
    title: z.string(),
    type: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_ThematicAxis = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    info: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    name: z.string(),
    thaxID: z.number(),
    updatedAt: z.string(),
  })
  .passthrough();
const thematicAxisCreate_Body = z
  .object({
    description: z.string(),
    info: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough()
      .nullish(),
    name: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_UrbanEquipmentView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
    ureqID: z.number(),
  })
  .passthrough();
const userRoleCreate_Body = z
  .object({ aproIDs: z.array(z.string()), userID: z.string() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_UserInstitutions = z
  .object({
    code: z.string(),
    instID: z.string().nullish(),
    inusID: z.number().nullish(),
    name: z.string(),
    operationalCode: z.string(),
    phone: z.string(),
    unit: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_UserInformation = z
  .object({
    applID: z.string(),
    authenticationProvider: z.string(),
    avatar: z.string().nullish(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    email: z.string().nullish(),
    info: z.object({}).partial().passthrough(),
    name: z.string(),
    pushTokens: z.array(z.string()).nullish(),
    roles: z.array(z.string()),
    userID: z.string(),
    userInstitutions: z.array(
      github_com_falqondev_PMSPDS_backend_service_UserInstitutions
    ),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_InstitutionUserCreateFormByUser =
  z
    .object({
      admittedAt: z.string().nullish(),
      buunID: z.number().nullish(),
      code: z.string().nullish(),
      instID: z.string(),
      operationalCode: z.string().nullish(),
      phone: z.string(),
      sex: z.string(),
    })
    .passthrough();
const userCreate_Body = z
  .object({
    applID: z.string(),
    avatar: z.string().nullish(),
    email: z.string(),
    institutions: z.array(
      github_com_falqondev_PMSPDS_backend_service_InstitutionUserCreateFormByUser
    ),
    name: z.string(),
    password: z.string().nullish(),
  })
  .passthrough();
const userUpdate_Body = z
  .object({
    avatar: z.string().nullable(),
    email: z.string().nullable(),
    name: z.string().nullable(),
  })
  .partial()
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_VulnerablePopulationView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
    vupoID: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_WorkOrderClassificationView =
  z
    .object({
      createdAt: z.string(),
      deletedAt: z.string().nullish(),
      instID: z.string().nullish(),
      institutionName: z.string().nullish(),
      name: z.string(),
      woclID: z.number(),
    })
    .passthrough();
const github_com_falqondev_PMSPDS_backend_service_WorkOrderProgramView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string(),
    institutionName: z.string().nullish(),
    name: z.string(),
    woprID: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_WorkOrderRequesterFunctionView =
  z
    .object({
      createdAt: z.string(),
      deletedAt: z.string().nullish(),
      instID: z.string().nullish(),
      institutionName: z.string().nullish(),
      name: z.string(),
      worfID: z.number(),
    })
    .passthrough();
const github_com_falqondev_PMSPDS_backend_service_WorkOrderRequesterTypeView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    instID: z.string().nullish(),
    institutionName: z.string().nullish(),
    name: z.string(),
    wortID: z.number(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_WorkOrderStatusView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    info: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    name: z.string(),
    reasons: z.array(z.string()).nullish(),
    requiredReason: z.boolean().nullish(),
    wostID: z.number(),
  })
  .passthrough();
const workOrderStatusCreate_Body = z
  .object({
    info: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    name: z.string(),
    reasons: z.array(z.string()).nullish(),
    requiredReason: z.boolean().nullish(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_WorkOrderSubprogramView = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    name: z.string(),
    woprID: z.number(),
    wosuID: z.number(),
  })
  .passthrough();
const workOrderSubprogramCreate_Body = z
  .object({ name: z.string(), woprID: z.number() })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_FieldAgentInfo = z
  .object({
    code: z.string(),
    name: z.string(),
    phone: z.string(),
    shiftEndAt: z.string(),
    shiftStartAt: z.string(),
    unit: z.string(),
    userID: z.string(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_WorkOrderView = z
  .object({
    address: z
      .object({
        city: z.string().nullable(),
        complement: z.string().nullable(),
        latitude: z.number().nullable(),
        longitude: z.number().nullable(),
        neighborhood: z.string().nullable(),
        number: z.string().nullable(),
        state: z.string().nullable(),
        street: z.string().nullable(),
        zipCode: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    attachment: z.string(),
    classification: z.string().nullish(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    endAt: z.string().nullish(),
    event: z.string(),
    extraordinaryEffective: z.number(),
    fieldAgents: z.array(
      github_com_falqondev_PMSPDS_backend_service_FieldAgentInfo
    ),
    generalEffective: z.number(),
    instID: z.string(),
    institutionName: z.string(),
    ordinaryEffective: z.number(),
    planningIdentifier: z.string(),
    programName: z.string(),
    recoID: z.number().nullish(),
    requester: z
      .object({
        documentNumber: z.string().nullable(),
        documentType: z.string().nullable(),
        name: z.string().nullable(),
        phone: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    requesterCompanyName: z.string().nullish(),
    requesterRole: z.string(),
    requesterType: z.string(),
    startAt: z.string().nullish(),
    status: z.string(),
    statusInfo: z
      .object({
        displayOptions: z
          .object({ color: z.string() })
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough(),
    statusReason: z.string().nullish(),
    subprogramName: z.string(),
    uid: z.string(),
    updatedAt: z.string(),
    urbanEquipmentName: z.string(),
    ureqID: z.number().nullish(),
    userID: z.string(),
    woclID: z.number().nullish(),
    wodoID: z.number().nullish(),
    wofaID: z.number().nullish(),
    woorID: z.string(),
    woprID: z.number().nullish(),
    worfID: z.number().nullish(),
    wortID: z.number().nullish(),
    wostID: z.number(),
    wosuID: z.number().nullish(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_FieldAgentUser = z
  .object({
    shiftEndAt: z.string().nullish(),
    shiftStartAt: z.string().nullish(),
    userID: z.string(),
  })
  .passthrough();
const workOrderCreate_Body = z
  .object({
    address: z
      .object({
        city: z.string().nullable(),
        complement: z.string().nullable(),
        latitude: z.number().nullable(),
        longitude: z.number().nullable(),
        neighborhood: z.string().nullable(),
        number: z.string().nullable(),
        state: z.string().nullable(),
        street: z.string().nullable(),
        zipCode: z.string().nullable(),
      })
      .partial()
      .passthrough()
      .nullish(),
    attachment: z.string().nullish(),
    description: z.string().nullish(),
    documents: z
      .array(github_com_falqondev_PMSPDS_backend_service_DocumentForm)
      .nullish(),
    endAt: z.string().nullish(),
    event: z.string().nullish(),
    extraordinaryEffective: z.number().nullish(),
    fieldAgentContact: z
      .object({ name: z.string().nullable(), phone: z.string().nullable() })
      .partial()
      .passthrough()
      .nullish(),
    fieldAgentUsers: z
      .array(github_com_falqondev_PMSPDS_backend_service_FieldAgentUser)
      .nullish(),
    generalEffective: z.number().nullish(),
    instID: z.string(),
    ordinaryEffective: z.number().nullish(),
    planningIdentifier: z.string().nullish(),
    recoID: z.number().nullish(),
    requester: z
      .object({
        documentNumber: z.string().nullable(),
        documentType: z.string().nullable(),
        name: z.string().nullable(),
        phone: z.string().nullable(),
      })
      .partial()
      .passthrough()
      .nullish(),
    startAt: z.string().nullish(),
    statusReason: z.string().nullish(),
    ureqID: z.number().nullish(),
    woclID: z.number().nullish(),
    woprID: z.number().nullish(),
    worfID: z.number().nullish(),
    wortID: z.number().nullish(),
    wostID: z.number(),
    wosuID: z.number().nullish(),
  })
  .passthrough();
const github_com_falqondev_PMSPDS_backend_service_WorkOrderDocumentView = z
  .object({
    createdAt: z.string(),
    data: z.unknown(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    docuID: z.string(),
    doteID: z.string(),
    formSchema: z.unknown(),
    permissionMode: z.string(),
    printSchema: z.unknown(),
    title: z.string(),
    uiSchema: z.unknown(),
    updatedAt: z.string(),
    wodoID: z.number(),
    woorID: z.string(),
  })
  .passthrough();

export const schemas = {
  github_com_falqondev_PMSPDS_backend_service_ActionPlanUserView,
  github_com_falqondev_PMSPDS_backend_service_ActionPlanView,
  github_com_falqondev_PMSPDS_backend_service_ActionPlanBudgetForm,
  actionPlanCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_ActionPlanCommentView,
  actionPlanCommentCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_ActionPlanBudgetHistory,
  github_com_falqondev_PMSPDS_backend_service_ActionPlanPeriodHistory,
  github_com_falqondev_PMSPDS_backend_service_ActionPlanStatusHistory,
  github_com_falqondev_PMSPDS_backend_service_ActionCommentView,
  github_com_falqondev_PMSPDS_backend_service_ActionView,
  actionCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_ActionPeriodHistory,
  github_com_falqondev_PMSPDS_backend_service_ActionStatusHistory,
  github_com_falqondev_PMSPDS_backend_service_ApplicationRole,
  github_com_falqondev_PMSPDS_backend_service_AuditLogView,
  github_com_falqondev_PMSPDS_backend_service_BudgetSource,
  github_com_falqondev_PMSPDS_backend_service_Rule,
  github_com_falqondev_PMSPDS_backend_service_BusinessMetricView,
  businessMetricCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_KPIValue,
  github_com_falqondev_PMSPDS_backend_service_CustomKPIView,
  customKPICreate_Body,
  github_com_falqondev_PMSPDS_backend_service_DashboardIncidentsByNature,
  github_com_falqondev_PMSPDS_backend_service_DashboardIncidentNeighborhood,
  github_com_falqondev_PMSPDS_backend_service_BudgetSummary,
  github_com_falqondev_PMSPDS_backend_service_PlanLatestComments,
  github_com_falqondev_PMSPDS_backend_service_PlanNextTask,
  github_com_falqondev_PMSPDS_backend_service_Resource,
  github_com_falqondev_PMSPDS_backend_service_ResourceCount,
  github_com_falqondev_PMSPDS_backend_service_DashboardSurveyResponse,
  github_com_falqondev_PMSPDS_backend_service_WorkOrderByProgram,
  github_com_falqondev_PMSPDS_backend_service_DashboardWorkOrderByProgram,
  github_com_falqondev_PMSPDS_backend_service_WorkOrderByStatus,
  github_com_falqondev_PMSPDS_backend_service_DashboardWorkOrderByStatus,
  github_com_falqondev_PMSPDS_backend_service_DefaultDescriptionTextView,
  defaultDescriptionTextCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_DispatchNatureView,
  dispatchNatureCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_DispatchStatusView,
  github_com_falqondev_PMSPDS_backend_service_DispatchSubnatureView,
  dispatchSubnatureCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_DispatchUnitView,
  github_com_falqondev_PMSPDS_backend_service_DocumentTemplate,
  documentTemplateCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_DocumentationView,
  documentationCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_Document,
  documentCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_FleetVehicleView,
  fleetVehicleCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_FormDocumentTemplateView,
  formDocumentTemplateCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_FormValidationView,
  formValidationCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_GuicheView,
  github_com_falqondev_PMSPDS_backend_service_IncidentActionTypeView,
  github_com_falqondev_PMSPDS_backend_service_IncidentDispatcherView,
  github_com_falqondev_PMSPDS_backend_service_IncidentEnvironmentView,
  github_com_falqondev_PMSPDS_backend_service_IncidentEstablishmentView,
  github_com_falqondev_PMSPDS_backend_service_IncidentNatureView,
  github_com_falqondev_PMSPDS_backend_service_IncidentOriginView,
  github_com_falqondev_PMSPDS_backend_service_IncidentProgramView,
  incidentProgramCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_IncidentResponseLocationView,
  github_com_falqondev_PMSPDS_backend_service_IncidentStatus,
  incidentStatusCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_IncidentSubnatureView,
  incidentSubnatureCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_IncidentSubprogramView,
  incidentSubprogramCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_Depredation,
  github_com_falqondev_PMSPDS_backend_service_IncidentVehicleView,
  github_com_falqondev_PMSPDS_backend_service_InvolvedPartyView,
  github_com_falqondev_PMSPDS_backend_service_SeizedItemView,
  github_com_falqondev_PMSPDS_backend_service_IncidentView,
  github_com_falqondev_PMSPDS_backend_service_DepredationForm,
  github_com_falqondev_PMSPDS_backend_service_DocumentForm,
  github_com_falqondev_PMSPDS_backend_service_IncidentVehicleForm,
  github_com_falqondev_PMSPDS_backend_service_InvolvedPartyForm,
  github_com_falqondev_PMSPDS_backend_service_SeizedItemForm,
  incidentCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_IncidentDocumentView,
  github_com_falqondev_PMSPDS_backend_service_Institution,
  institutionCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_BusinessUnit,
  institutionUnitCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_InstitutionUserView,
  institutionUserCreate_Body,
  institutionUserUpdate_Body,
  oneTimeLoginCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_PhysicalConditionView,
  github_com_falqondev_PMSPDS_backend_service_ProjetcUserView,
  github_com_falqondev_PMSPDS_backend_service_PlanView,
  plansCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_ProgramUserView,
  github_com_falqondev_PMSPDS_backend_service_ProgramView,
  github_com_falqondev_PMSPDS_backend_service_ProgramBudgetForm,
  programCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_ProgramBudgetHistory,
  github_com_falqondev_PMSPDS_backend_service_ProgramPeriodHistory,
  github_com_falqondev_PMSPDS_backend_service_ProgramStatusHistory,
  github_com_falqondev_PMSPDS_backend_service_ProjectView,
  github_com_falqondev_PMSPDS_backend_service_ProjectBudgetForm,
  projectCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_ProjectBudgetHistory,
  github_com_falqondev_PMSPDS_backend_service_ProjectPeriodHistory,
  github_com_falqondev_PMSPDS_backend_service_ProjectStatusHistory,
  github_com_falqondev_PMSPDS_backend_service_PublicEquipmentView,
  github_com_falqondev_PMSPDS_backend_service_RequestingCompanyView,
  github_com_falqondev_PMSPDS_backend_service_ServiceChannelView,
  github_com_falqondev_PMSPDS_backend_service_SurveyResponseData,
  github_com_falqondev_PMSPDS_backend_service_SurveyResponseListing,
  surveyResponseCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_SurveyForm,
  github_com_falqondev_PMSPDS_backend_service_Survey,
  github_com_falqondev_PMSPDS_backend_service_InputSurveyForm,
  surveyCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_MySurvey,
  github_com_falqondev_PMSPDS_backend_service_PendingTerm,
  github_com_falqondev_PMSPDS_backend_service_Term,
  termCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_ThematicAxis,
  thematicAxisCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_UrbanEquipmentView,
  userRoleCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_UserInstitutions,
  github_com_falqondev_PMSPDS_backend_service_UserInformation,
  github_com_falqondev_PMSPDS_backend_service_InstitutionUserCreateFormByUser,
  userCreate_Body,
  userUpdate_Body,
  github_com_falqondev_PMSPDS_backend_service_VulnerablePopulationView,
  github_com_falqondev_PMSPDS_backend_service_WorkOrderClassificationView,
  github_com_falqondev_PMSPDS_backend_service_WorkOrderProgramView,
  github_com_falqondev_PMSPDS_backend_service_WorkOrderRequesterFunctionView,
  github_com_falqondev_PMSPDS_backend_service_WorkOrderRequesterTypeView,
  github_com_falqondev_PMSPDS_backend_service_WorkOrderStatusView,
  workOrderStatusCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_WorkOrderSubprogramView,
  workOrderSubprogramCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_FieldAgentInfo,
  github_com_falqondev_PMSPDS_backend_service_WorkOrderView,
  github_com_falqondev_PMSPDS_backend_service_FieldAgentUser,
  workOrderCreate_Body,
  github_com_falqondev_PMSPDS_backend_service_WorkOrderDocumentView,
};

const endpoints = makeApi([
  {
    method: "get",
    path: "/api/action-plan",
    alias: "actionPlanList",
    description: `Lists all action plans`,
    requestFormat: "json",
    parameters: [
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "projID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inCharge",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inusID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "thaxID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "acplID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ActionPlanView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/action-plan",
    alias: "actionPlanCreate",
    description: `Create new action plan`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: actionPlanCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                actionCount: z.number(),
                actionPlanUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ActionPlanUserView
                ),
                attachment: z.string().nullish(),
                budgets: z.array(
                  z
                    .object({
                      actual: z.number(),
                      budgID: z.string(),
                      busoID: z.number(),
                      predicted: z.number(),
                      received: z.number(),
                    })
                    .passthrough()
                ),
                comments: z.number().nullish(),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                institutionUserAvatar: z.string().nullish(),
                institutionUserEmail: z.string().nullish(),
                institutionUserName: z.string(),
                institutions: z.array(z.string()),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                projID: z.string(),
                projectInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/action-plan/:acplID",
    alias: "actionPlanDelete",
    description: `Delete one action plan by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                attachment: z.string().nullish(),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                inusID: z.number(),
                name: z.string(),
                projID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/action-plan/:acplID",
    alias: "actionPlanGet",
    description: `Get one action plan`,
    requestFormat: "json",
    parameters: [
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                actionCount: z.number(),
                actionPlanUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ActionPlanUserView
                ),
                attachment: z.string().nullish(),
                budgets: z.array(
                  z
                    .object({
                      actual: z.number(),
                      budgID: z.string(),
                      busoID: z.number(),
                      predicted: z.number(),
                      received: z.number(),
                    })
                    .passthrough()
                ),
                comments: z.number().nullish(),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                institutionUserAvatar: z.string().nullish(),
                institutionUserEmail: z.string().nullish(),
                institutionUserName: z.string(),
                institutions: z.array(z.string()),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                projID: z.string(),
                projectInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/action-plan/:acplID",
    alias: "actionPlanUpdate",
    description: `Update one action plan`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: actionPlanCreate_Body,
      },
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                actionCount: z.number(),
                actionPlanUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ActionPlanUserView
                ),
                attachment: z.string().nullish(),
                budgets: z.array(
                  z
                    .object({
                      actual: z.number(),
                      budgID: z.string(),
                      busoID: z.number(),
                      predicted: z.number(),
                      received: z.number(),
                    })
                    .passthrough()
                ),
                comments: z.number().nullish(),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                institutionUserAvatar: z.string().nullish(),
                institutionUserEmail: z.string().nullish(),
                institutionUserName: z.string(),
                institutions: z.array(z.string()),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                projID: z.string(),
                projectInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/action-plan/:acplID/comment",
    alias: "actionPlanCommentList",
    description: `Lists all action plan comments`,
    requestFormat: "json",
    parameters: [
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "commID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "message",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ActionPlanCommentView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/action-plan/:acplID/comment",
    alias: "actionPlanCommentCreate",
    description: `Create new action plan comment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: actionPlanCommentCreate_Body,
      },
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                attachment: z.string().nullish(),
                commID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                message: z.string(),
                userID: z.string(),
                userName: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/action-plan/:acplID/comment/:commID",
    alias: "actionPlanCommentDelete",
    description: `Delete one action plan comment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "commID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                attachment: z.string().nullish(),
                commID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                message: z.string(),
                userID: z.string(),
                userName: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/action-plan/:acplID/comment/:commID",
    alias: "actionPlanCommentGet",
    description: `Get one action plan comment`,
    requestFormat: "json",
    parameters: [
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "commID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                attachment: z.string().nullish(),
                commID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                message: z.string(),
                userID: z.string(),
                userName: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/action-plan/:acplID/history/budget",
    alias: "actionPlanBudgetHistory",
    description: `Lists all action plan budget`,
    requestFormat: "json",
    parameters: [
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ActionPlanBudgetHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/action-plan/:acplID/history/period",
    alias: "actionPlanPeriodHistory",
    description: `Lists all action plan periods`,
    requestFormat: "json",
    parameters: [
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ActionPlanPeriodHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/action-plan/:acplID/history/status",
    alias: "actionPlanStatusHistory",
    description: `Lists all action plan status`,
    requestFormat: "json",
    parameters: [
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ActionPlanStatusHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/action/:actiID/comment",
    alias: "actionCommentList",
    description: `Lists all action plan comments`,
    requestFormat: "json",
    parameters: [
      {
        name: "actiID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "commID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "message",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ActionCommentView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/action/:actiID/comment",
    alias: "actionCommentCreate",
    description: `Create new action plan comment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: actionPlanCommentCreate_Body,
      },
      {
        name: "actiID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                actiID: z.string(),
                attachment: z.string().nullish(),
                commID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                message: z.string(),
                userID: z.string(),
                userName: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/action/:actiID/comment/:commID",
    alias: "actionCommentDelete",
    description: `Delete one action plan comment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "commID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "actiID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                actiID: z.string(),
                attachment: z.string().nullish(),
                commID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                message: z.string(),
                userID: z.string(),
                userName: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/action/:actiID/comment/:commID",
    alias: "actionCommentGet",
    description: `Get one action plan comment`,
    requestFormat: "json",
    parameters: [
      {
        name: "actiID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "commID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                actiID: z.string(),
                attachment: z.string().nullish(),
                commID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                message: z.string(),
                userID: z.string(),
                userName: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/actions",
    alias: "actionList",
    description: `Lists all actions`,
    requestFormat: "json",
    parameters: [
      {
        name: "thaxID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "actiID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "inCharge",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "acplID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "inusID",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ActionView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/actions",
    alias: "actionCreate",
    description: `Create new action`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: actionCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                actiID: z.string(),
                comments: z.number().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                projectInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                status: z.string(),
                statusAttachment: z.string().nullish(),
                statusMessage: z.string(),
                userAvatar: z.string().nullish(),
                userEmail: z.string().nullish(),
                userName: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/actions/:actiID",
    alias: "actionDelete",
    description: `Delete one action by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "actiID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                actiID: z.string(),
                comments: z.number().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                projectInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                status: z.string(),
                statusAttachment: z.string().nullish(),
                statusMessage: z.string(),
                userAvatar: z.string().nullish(),
                userEmail: z.string().nullish(),
                userName: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/actions/:actiID",
    alias: "actionGet",
    description: `Get one action`,
    requestFormat: "json",
    parameters: [
      {
        name: "actiID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                actiID: z.string(),
                comments: z.number().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                projectInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                status: z.string(),
                statusAttachment: z.string().nullish(),
                statusMessage: z.string(),
                userAvatar: z.string().nullish(),
                userEmail: z.string().nullish(),
                userName: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/actions/:actiID",
    alias: "actionUpdate",
    description: `Update one action`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: actionCreate_Body,
      },
      {
        name: "actiID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acplID: z.string(),
                actiID: z.string(),
                comments: z.number().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                projectInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                status: z.string(),
                statusAttachment: z.string().nullish(),
                statusMessage: z.string(),
                userAvatar: z.string().nullish(),
                userEmail: z.string().nullish(),
                userName: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/actions/:actiID/history/period",
    alias: "actionPeriodHistory",
    description: `Lists all action periods`,
    requestFormat: "json",
    parameters: [
      {
        name: "actiID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ActionPeriodHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/actions/:actiID/history/status",
    alias: "actionStatusHistory",
    description: `Lists all action status`,
    requestFormat: "json",
    parameters: [
      {
        name: "actiID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ActionStatusHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/application-roles",
    alias: "applicationRoleList",
    description: `Lists all roles available`,
    requestFormat: "json",
    parameters: [
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "aproID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ApplicationRole
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/audit-logs",
    alias: "auditLogsList",
    description: `Lists all logs`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "resource",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "action",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "userName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "userEmail",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_AuditLogView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/audit-logs/download",
    alias: "auditLogsDownload",
    description: `Get logs export file`,
    requestFormat: "json",
    parameters: [
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "userEmail",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "action",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "resource",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "userName",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/auth-media",
    alias: "mediaAuth",
    description: `Authenticate media server access`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z
          .object({ mediaURL: z.string().nullable() })
          .partial()
          .passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ jwt: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/budget-source",
    alias: "budgetSourceList",
    description: `Lists all budget sources`,
    requestFormat: "json",
    parameters: [
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "busoIDs",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "active",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_BudgetSource
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/budget-source",
    alias: "budgetSourceCreate",
    description: `Create one budget source`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                busoID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/budget-source/:busoID",
    alias: "budgetSourceDelete",
    description: `Delete one budget source by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "busoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                busoID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/budget-source/:busoID",
    alias: "budgetSourceGet",
    description: `Get one budget source`,
    requestFormat: "json",
    parameters: [
      {
        name: "busoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                busoID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/budget-source/:busoID",
    alias: "budgetSourceUpdate",
    description: `Update budget source`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "busoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                busoID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/business-metric",
    alias: "businessMetricList",
    description: `Lists all business metrics`,
    requestFormat: "json",
    parameters: [
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "projID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "bumeID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "thaxID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "planID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inCharge",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "active",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "bumeIDs",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "progID",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_BusinessMetricView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/business-metric",
    alias: "businessMetricCreate",
    description: `Create one business metric`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: businessMetricCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                bumeID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                kpiProgress: z
                  .object({
                    actual: z.number(),
                    displayOption: z.string(),
                    offTrack: z.number(),
                    onTrack: z.number(),
                    referenceDate: z.string().nullish(),
                    referenceValue: z.number(),
                  })
                  .passthrough(),
                name: z.string(),
                planID: z.string(),
                progID: z.string().nullish(),
                projID: z.string().nullish(),
                projectInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                rule: z.array(github_com_falqondev_PMSPDS_backend_service_Rule),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/business-metric/:bumeID",
    alias: "businessMetricDelete",
    description: `Delete one business metric by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "bumeID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                bumeID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                name: z.string(),
                planID: z.string(),
                progID: z.string().nullish(),
                projID: z.string().nullish(),
                rule: z.array(github_com_falqondev_PMSPDS_backend_service_Rule),
                status: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/business-metric/:bumeID",
    alias: "businessMetricGet",
    description: `Get one business metric`,
    requestFormat: "json",
    parameters: [
      {
        name: "bumeID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                bumeID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                kpiProgress: z
                  .object({
                    actual: z.number(),
                    displayOption: z.string(),
                    offTrack: z.number(),
                    onTrack: z.number(),
                    referenceDate: z.string().nullish(),
                    referenceValue: z.number(),
                  })
                  .passthrough(),
                name: z.string(),
                planID: z.string(),
                progID: z.string().nullish(),
                projID: z.string().nullish(),
                projectInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                rule: z.array(github_com_falqondev_PMSPDS_backend_service_Rule),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/business-metric/:bumeID",
    alias: "businessMetricUpdate",
    description: `Update business metric`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: businessMetricCreate_Body,
      },
      {
        name: "bumeID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                bumeID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                kpiProgress: z
                  .object({
                    actual: z.number(),
                    displayOption: z.string(),
                    offTrack: z.number(),
                    onTrack: z.number(),
                    referenceDate: z.string().nullish(),
                    referenceValue: z.number(),
                  })
                  .passthrough(),
                name: z.string(),
                planID: z.string(),
                progID: z.string().nullish(),
                projID: z.string().nullish(),
                projectInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                rule: z.array(github_com_falqondev_PMSPDS_backend_service_Rule),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/custom-kpi",
    alias: "customKPIList",
    description: `Lists all custom KPI`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "cukpID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_CustomKPIView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/custom-kpi",
    alias: "customKPICreate",
    description: `Create new custom KPI`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: customKPICreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                cukpID: z.number(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                name: z.string(),
                updatedAt: z.string(),
                values: z.array(
                  github_com_falqondev_PMSPDS_backend_service_KPIValue
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/custom-kpi/:cukpID",
    alias: "customKPIDelete",
    description: `Delete one program by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "cukpID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                cukpID: z.number(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                name: z.string(),
                updatedAt: z.string(),
                values: z.array(
                  github_com_falqondev_PMSPDS_backend_service_KPIValue
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/custom-kpi/:cukpID",
    alias: "customKPIGet",
    description: `Get one custom KPI`,
    requestFormat: "json",
    parameters: [
      {
        name: "cukpID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                cukpID: z.number(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                name: z.string(),
                updatedAt: z.string(),
                values: z.array(
                  github_com_falqondev_PMSPDS_backend_service_KPIValue
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/custom-kpi/:cukpID",
    alias: "customKPIUpdate",
    description: `Update one custom KPI`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: customKPICreate_Body,
      },
      {
        name: "cukpID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                cukpID: z.number(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                name: z.string(),
                updatedAt: z.string(),
                values: z.array(
                  github_com_falqondev_PMSPDS_backend_service_KPIValue
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/incident/nature",
    alias: "dashboardIncidentNature",
    description: `Lists incidents by nature`,
    requestFormat: "json",
    parameters: [
      {
        name: "innaID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "natureName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "InciID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DashboardIncidentsByNature
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/incident/neighborhood",
    alias: "dashboardIncidentNeighborhood",
    description: `Lists incidents in neighborhood`,
    requestFormat: "json",
    parameters: [
      {
        name: "InciID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "neighborhood",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DashboardIncidentNeighborhood
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/incident/period",
    alias: "dashboardIncidentPeriod",
    description: `Get incidents by period`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inciID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdDate[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdDate[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                averageResponseTime: z
                  .object({
                    average: z.string(),
                    comparativePercentageLastMonth: z.number(),
                    comparativePercentageLastYear: z.number(),
                  })
                  .passthrough(),
                incidentProgression: z
                  .object({
                    comparativePercentageLastMonth: z.number(),
                    comparativePercentageLastYear: z.number(),
                    percentage: z.number(),
                  })
                  .passthrough(),
                securityIncidents: z
                  .object({
                    comparativePercentageLastMonth: z.number(),
                    comparativePercentageLastYear: z.number(),
                    total: z.number(),
                  })
                  .passthrough(),
                serviceIncidents: z
                  .object({
                    comparativePercentageLastMonth: z.number(),
                    comparativePercentageLastYear: z.number(),
                    total: z.number(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/plans/:planID",
    alias: "plansDashboard",
    description: `Get plan dashboard`,
    requestFormat: "json",
    parameters: [
      {
        name: "planID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "budgetSourceName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "thaxID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                budgetSourceName: z.array(z.string()),
                planID: z.string(),
                resourceTimeline: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ResourceCount
                ),
                totalActual: z.number(),
                totalPredicted: z.number(),
                totalReceived: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/plans/budget-summary/:planID",
    alias: "plansBudgetSummary",
    description: `Get plan budget summary`,
    requestFormat: "json",
    parameters: [
      {
        name: "planID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "period[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "period[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                budgetSummary: z.array(
                  github_com_falqondev_PMSPDS_backend_service_BudgetSummary
                ),
                percentageUsage: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/plans/comments/:planID",
    alias: "plansLatestComments",
    description: `Get plan next tasks`,
    requestFormat: "json",
    parameters: [
      {
        name: "planID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "thaxID",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_PlanLatestComments
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/plans/next-task/:planID",
    alias: "plansNextTasks",
    description: `Get plan next tasks`,
    requestFormat: "json",
    parameters: [
      {
        name: "planID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "thaxID",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_PlanNextTask
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/plans/summary/:planID",
    alias: "plansSummary",
    description: `Get plan summary`,
    requestFormat: "json",
    parameters: [
      {
        name: "planID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "thaxID",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                resourceSummary: z.array(
                  github_com_falqondev_PMSPDS_backend_service_Resource
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/plans/task-summary/:planID",
    alias: "plansTaskSummary",
    description: `Get plan task summary`,
    requestFormat: "json",
    parameters: [
      {
        name: "planID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "thaxID",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                finishedPercentage: z.number(),
                finishedTasks: z.number(),
                openTasks: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/programs/:progID/final-budget",
    alias: "programFinalBudgetGet",
    description: `Get one program final budget`,
    requestFormat: "json",
    parameters: [
      {
        name: "progID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({ progID: z.string(), totalActual: z.number() })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/projects/:projID/final-budget",
    alias: "projectFinalBudgetGet",
    description: `Get one project final budget`,
    requestFormat: "json",
    parameters: [
      {
        name: "projID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({ projID: z.string(), totalActual: z.number() })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/survey/period",
    alias: "dashboardSurveyPeriod",
    description: `Get poll data by period`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "survID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "responseDate[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "responseDate[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                pools: z.number(),
                responses: z.array(
                  github_com_falqondev_PMSPDS_backend_service_DashboardSurveyResponse
                ),
                survIDs: z.array(z.string()),
                totalAnswers: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/work-order",
    alias: "dashboardWorkOrder",
    description: `Get work order count`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                MonthTotal: z
                  .object({
                    comparativePercentageLastYear: z.number(),
                    total: z.number(),
                  })
                  .passthrough(),
                YearTotal: z
                  .object({
                    comparativePercentageLastYear: z.number(),
                    total: z.number(),
                  })
                  .passthrough(),
                averagePerYear: z.number(),
                total: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/work-order/program",
    alias: "dashboardWorkOrderByProgram",
    description: `Lists work order by program`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "programName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "woorID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "woprID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "wostID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DashboardWorkOrderByProgram
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dashboard/work-order/status",
    alias: "dashboardWorkOrderByStatus",
    description: `Lists work order by status`,
    requestFormat: "json",
    parameters: [
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "startDate",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "wostID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "range",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "woorID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "endDate",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "status",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DashboardWorkOrderByStatus
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/default-description-texts",
    alias: "defaultDescriptionTextList",
    description: `Lists all default description texts`,
    requestFormat: "json",
    parameters: [
      {
        name: "formName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "ddteID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DefaultDescriptionTextView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/default-description-texts",
    alias: "defaultDescriptionTextCreate",
    description: `Create one default description text`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: defaultDescriptionTextCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                ddteID: z.number(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                formField: z.string(),
                formName: z.string(),
                instID: z.string(),
                institutionName: z.string(),
                name: z.string(),
                placeholder: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/default-description-texts/:ddteID",
    alias: "defaultDescriptionTextDelete",
    description: `Delete one default description text by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "ddteID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                ddteID: z.number(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                formField: z.string(),
                formName: z.string(),
                instID: z.string(),
                institutionName: z.string(),
                name: z.string(),
                placeholder: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/default-description-texts/:ddteID",
    alias: "defaultDescriptionTextGet",
    description: `Get one default description text`,
    requestFormat: "json",
    parameters: [
      {
        name: "ddteID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                ddteID: z.number(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                formField: z.string(),
                formName: z.string(),
                instID: z.string(),
                institutionName: z.string(),
                name: z.string(),
                placeholder: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/default-description-texts/:ddteID",
    alias: "defaultDescriptionTextUpdate",
    description: `Update default description text`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: defaultDescriptionTextCreate_Body,
      },
      {
        name: "ddteID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                ddteID: z.number(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                formField: z.string(),
                formName: z.string(),
                instID: z.string(),
                institutionName: z.string(),
                name: z.string(),
                placeholder: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dispatch-natures",
    alias: "dispatchNatureList",
    description: `Lists all dispatch_natures`,
    requestFormat: "json",
    parameters: [
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "dinaID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DispatchNatureView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/dispatch-natures",
    alias: "dispatchNatureCreate",
    description: `Create one dispatch_nature`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                dinaID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/dispatch-natures/:dinaID",
    alias: "dispatchNatureDelete",
    description: `Delete one dispatch_nature by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "dinaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                dinaID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dispatch-natures/:dinaID",
    alias: "dispatchNatureGet",
    description: `Get one dispatch_nature`,
    requestFormat: "json",
    parameters: [
      {
        name: "dinaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                dinaID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/dispatch-natures/:dinaID",
    alias: "dispatchNatureUpdate",
    description: `Update dispatch_nature`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "dinaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                dinaID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dispatch-status",
    alias: "dispatchStatusList",
    description: `Lists all dispatch_statuss`,
    requestFormat: "json",
    parameters: [
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "distID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DispatchStatusView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/dispatch-status",
    alias: "dispatchStatusCreate",
    description: `Create one dispatch_status`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                distID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/dispatch-status/:distID",
    alias: "dispatchStatusDelete",
    description: `Delete one dispatch_status by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "distID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                distID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dispatch-status/:distID",
    alias: "dispatchStatusGet",
    description: `Get one dispatch_status`,
    requestFormat: "json",
    parameters: [
      {
        name: "distID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                distID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/dispatch-status/:distID",
    alias: "dispatchStatusUpdate",
    description: `Update dispatch_status`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "distID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                distID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dispatch-subnatures",
    alias: "dispatchSubnatureList",
    description: `Lists all dispatch_subnatures`,
    requestFormat: "json",
    parameters: [
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "disuID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "dinaID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DispatchSubnatureView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/dispatch-subnatures",
    alias: "dispatchSubnatureCreate",
    description: `Create one dispatch_subnature`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchSubnatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                dinaID: z.number(),
                disuID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/dispatch-subnatures/:disuID",
    alias: "dispatchSubnatureDelete",
    description: `Delete one dispatch_subnature by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "disuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                dinaID: z.number(),
                disuID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dispatch-subnatures/:disuID",
    alias: "dispatchSubnatureGet",
    description: `Get one dispatch_subnature`,
    requestFormat: "json",
    parameters: [
      {
        name: "disuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                dinaID: z.number(),
                disuID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/dispatch-subnatures/:disuID",
    alias: "dispatchSubnatureUpdate",
    description: `Update dispatch_subnature`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "disuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                dinaID: z.number(),
                disuID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dispatch-units",
    alias: "dispatchUnitList",
    description: `Lists all dispatch_units`,
    requestFormat: "json",
    parameters: [
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "diunID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DispatchUnitView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/dispatch-units",
    alias: "dispatchUnitCreate",
    description: `Create one dispatch_unit`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                diunID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/dispatch-units/:diunID",
    alias: "dispatchUnitDelete",
    description: `Delete one dispatch_unit by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "diunID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                diunID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/dispatch-units/:diunID",
    alias: "dispatchUnitGet",
    description: `Get one dispatch_unit`,
    requestFormat: "json",
    parameters: [
      {
        name: "diunID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                diunID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/dispatch-units/:diunID",
    alias: "dispatchUnitUpdate",
    description: `Update dispatch_unit`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "diunID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                diunID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/document-templates",
    alias: "documentTemplateList",
    description: `Lists all document template`,
    requestFormat: "json",
    parameters: [
      {
        name: "doteID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "title",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "isTemplate",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "isFormTemplate",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "survID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DocumentTemplate
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/document-templates",
    alias: "documentTemplateCreate",
    description: `Create one document template`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: documentTemplateCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                defaultData: z.unknown(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                doteID: z.string(),
                formSchema: z.unknown(),
                permissionMode: z.string(),
                printSchema: z.unknown(),
                title: z.string(),
                uiSchema: z.unknown(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/document-templates/:doteID",
    alias: "documentTemplateDelete",
    description: `Delete one document template by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "doteID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                defaultData: z.unknown(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                doteID: z.string(),
                formSchema: z.unknown(),
                permissionMode: z.string(),
                printSchema: z.unknown(),
                title: z.string(),
                uiSchema: z.unknown(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/document-templates/:doteID",
    alias: "documentTemplateGet",
    description: `Get one document template`,
    requestFormat: "json",
    parameters: [
      {
        name: "doteID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                defaultData: z.unknown(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                doteID: z.string(),
                formSchema: z.unknown(),
                permissionMode: z.string(),
                printSchema: z.unknown(),
                title: z.string(),
                uiSchema: z.unknown(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/document-templates/:doteID",
    alias: "documentTemplateUpdate",
    description: `Update document template`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: documentTemplateCreate_Body,
      },
      {
        name: "doteID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                defaultData: z.unknown(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                doteID: z.string(),
                formSchema: z.unknown(),
                permissionMode: z.string(),
                printSchema: z.unknown(),
                title: z.string(),
                uiSchema: z.unknown(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/documentations",
    alias: "documentationList",
    description: `Lists all documentations`,
    requestFormat: "json",
    parameters: [
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "title",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "docuIDs",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "tags",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_DocumentationView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/documentations",
    alias: "documentationCreate",
    description: `Create one documentation`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: documentationCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                content: z.string(),
                createdAt: z.string(),
                docuID: z.number(),
                tags: z.array(z.string()),
                title: z.string(),
                videoURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/documentations/:docuID",
    alias: "documentationDelete",
    description: `Delete one documentation by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "docuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                content: z.string(),
                createdAt: z.string(),
                docuID: z.number(),
                tags: z.array(z.string()),
                title: z.string(),
                videoURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/documentations/:docuID",
    alias: "documentationGet",
    description: `Get one documentation`,
    requestFormat: "json",
    parameters: [
      {
        name: "docuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                content: z.string(),
                createdAt: z.string(),
                docuID: z.number(),
                tags: z.array(z.string()),
                title: z.string(),
                videoURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/documentations/:docuID",
    alias: "documentationUpdate",
    description: `Update documentation`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: documentationCreate_Body,
      },
      {
        name: "docuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                content: z.string(),
                createdAt: z.string(),
                docuID: z.number(),
                tags: z.array(z.string()),
                title: z.string(),
                videoURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/documents",
    alias: "documentList",
    description: `Lists all document`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "Limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "Offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_Document
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/documents",
    alias: "documentCreate",
    description: `Create one document`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: documentCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                data: z.unknown(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                docuID: z.string(),
                doteID: z.string(),
                formSchema: z.unknown(),
                permissionMode: z.string(),
                printSchema: z.unknown(),
                title: z.string(),
                uiSchema: z.unknown(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/documents/:docuID",
    alias: "documentDelete",
    description: `Delete one document by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "docuID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                data: z.unknown(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                docuID: z.string(),
                doteID: z.string(),
                formSchema: z.unknown(),
                permissionMode: z.string(),
                printSchema: z.unknown(),
                title: z.string(),
                uiSchema: z.unknown(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/documents/:docuID",
    alias: "documentGet",
    description: `Get one document`,
    requestFormat: "json",
    parameters: [
      {
        name: "docuID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                data: z.unknown(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                docuID: z.string(),
                doteID: z.string(),
                formSchema: z.unknown(),
                permissionMode: z.string(),
                printSchema: z.unknown(),
                title: z.string(),
                uiSchema: z.unknown(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/documents/:docuID",
    alias: "documentUpdate",
    description: `Update document`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: documentCreate_Body,
      },
      {
        name: "docuID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                data: z.unknown(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                docuID: z.string(),
                doteID: z.string(),
                formSchema: z.unknown(),
                permissionMode: z.string(),
                printSchema: z.unknown(),
                title: z.string(),
                uiSchema: z.unknown(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/fleet-vehicles",
    alias: "fleetVehicleList",
    description: `Lists all fleet vehicles`,
    requestFormat: "json",
    parameters: [
      {
        name: "flveID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "active",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "licensePlate",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "searchText",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_FleetVehicleView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/fleet-vehicles",
    alias: "fleetVehicleCreate",
    description: `Create one fleet vehicle`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: fleetVehicleCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                details: z
                  .object({
                    carrying_capacity: z.string(),
                    color: z.string(),
                    dimensions: z
                      .object({
                        height: z.number(),
                        length: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    engine: z
                      .object({ capacity: z.string(), type: z.string() })
                      .passthrough(),
                    features: z.array(z.string()),
                    mileage_km: z.number(),
                    purchase_date: z.string(),
                    seat_numbers: z.number(),
                    serial_number: z.string(),
                    type: z.string(),
                    warranty: z
                      .object({
                        details: z.string().nullish(),
                        expiry_date: z.string(),
                      })
                      .passthrough(),
                  })
                  .passthrough(),
                flveID: z.number(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                licensePlate: z.string(),
                make: z.string(),
                model: z.string(),
                registrationNumber: z.string(),
                updatedAt: z.string(),
                year: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/fleet-vehicles/:flveID",
    alias: "fleetVehicleDelete",
    description: `Delete one fleet vehicle by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "flveID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                details: z
                  .object({
                    carrying_capacity: z.string(),
                    color: z.string(),
                    dimensions: z
                      .object({
                        height: z.number(),
                        length: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    engine: z
                      .object({ capacity: z.string(), type: z.string() })
                      .passthrough(),
                    features: z.array(z.string()),
                    mileage_km: z.number(),
                    purchase_date: z.string(),
                    seat_numbers: z.number(),
                    serial_number: z.string(),
                    type: z.string(),
                    warranty: z
                      .object({
                        details: z.string().nullish(),
                        expiry_date: z.string(),
                      })
                      .passthrough(),
                  })
                  .passthrough(),
                flveID: z.number(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                licensePlate: z.string(),
                make: z.string(),
                model: z.string(),
                registrationNumber: z.string(),
                updatedAt: z.string(),
                year: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/fleet-vehicles/:flveID",
    alias: "fleetVehicleGet",
    description: `Get one fleet vehicle`,
    requestFormat: "json",
    parameters: [
      {
        name: "flveID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                details: z
                  .object({
                    carrying_capacity: z.string(),
                    color: z.string(),
                    dimensions: z
                      .object({
                        height: z.number(),
                        length: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    engine: z
                      .object({ capacity: z.string(), type: z.string() })
                      .passthrough(),
                    features: z.array(z.string()),
                    mileage_km: z.number(),
                    purchase_date: z.string(),
                    seat_numbers: z.number(),
                    serial_number: z.string(),
                    type: z.string(),
                    warranty: z
                      .object({
                        details: z.string().nullish(),
                        expiry_date: z.string(),
                      })
                      .passthrough(),
                  })
                  .passthrough(),
                flveID: z.number(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                licensePlate: z.string(),
                make: z.string(),
                model: z.string(),
                registrationNumber: z.string(),
                updatedAt: z.string(),
                year: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/fleet-vehicles/:flveID",
    alias: "fleetVehicleUpdate",
    description: `Update fleet vehicle`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: fleetVehicleCreate_Body,
      },
      {
        name: "flveID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                details: z
                  .object({
                    carrying_capacity: z.string(),
                    color: z.string(),
                    dimensions: z
                      .object({
                        height: z.number(),
                        length: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    engine: z
                      .object({ capacity: z.string(), type: z.string() })
                      .passthrough(),
                    features: z.array(z.string()),
                    mileage_km: z.number(),
                    purchase_date: z.string(),
                    seat_numbers: z.number(),
                    serial_number: z.string(),
                    type: z.string(),
                    warranty: z
                      .object({
                        details: z.string().nullish(),
                        expiry_date: z.string(),
                      })
                      .passthrough(),
                  })
                  .passthrough(),
                flveID: z.number(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                licensePlate: z.string(),
                make: z.string(),
                model: z.string(),
                registrationNumber: z.string(),
                updatedAt: z.string(),
                year: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/form-document-templates",
    alias: "formDocumentTemplateList",
    description: `Lists all form_document_templates`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "fodtID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "formName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_FormDocumentTemplateView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/form-document-templates",
    alias: "formDocumentTemplateCreate",
    description: `Create one form_document_template`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: formDocumentTemplateCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                doteID: z.string(),
                fodtID: z.number(),
                formName: z.string(),
                instID: z.string(),
                institutionName: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/form-document-templates/:fodtID",
    alias: "formDocumentTemplateDelete",
    description: `Delete one form_document_template by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "fodtID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                doteID: z.string(),
                fodtID: z.number(),
                formName: z.string(),
                instID: z.string(),
                institutionName: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/form-document-templates/:fodtID",
    alias: "formDocumentTemplateGet",
    description: `Get one form_document_template`,
    requestFormat: "json",
    parameters: [
      {
        name: "fodtID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                doteID: z.string(),
                fodtID: z.number(),
                formName: z.string(),
                instID: z.string(),
                institutionName: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/form-document-templates/:fodtID",
    alias: "formDocumentTemplateUpdate",
    description: `Update form_document_template`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: formDocumentTemplateCreate_Body,
      },
      {
        name: "fodtID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                doteID: z.string(),
                fodtID: z.number(),
                formName: z.string(),
                instID: z.string(),
                institutionName: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/form-validations",
    alias: "formValidationList",
    description: `Lists all form_validations`,
    requestFormat: "json",
    parameters: [
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "formName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_FormValidationView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/form-validations",
    alias: "formValidationCreate",
    description: `Create one form_validation`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: formValidationCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                formName: z.string(),
                fovaID: z.number(),
                instID: z.string(),
                institutionName: z.string(),
                validationSchema: z.unknown(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/form-validations/:fovaID",
    alias: "formValidationDelete",
    description: `Delete one form_validation by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "fovaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                formName: z.string(),
                fovaID: z.number(),
                instID: z.string(),
                institutionName: z.string(),
                validationSchema: z.unknown(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/form-validations/:fovaID",
    alias: "formValidationGet",
    description: `Get one form_validation`,
    requestFormat: "json",
    parameters: [
      {
        name: "fovaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                formName: z.string(),
                fovaID: z.number(),
                instID: z.string(),
                institutionName: z.string(),
                validationSchema: z.unknown(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/form-validations/:fovaID",
    alias: "formValidationUpdate",
    description: `Update form_validation`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: formValidationCreate_Body,
      },
      {
        name: "fovaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                formName: z.string(),
                fovaID: z.number(),
                instID: z.string(),
                institutionName: z.string(),
                validationSchema: z.unknown(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/guiche",
    alias: "guicheList",
    description: `Lists all guiches`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "guicID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_GuicheView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/guiche",
    alias: "guicheCreate",
    description: `Create one guiche`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                guicID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/guiche/:guicID",
    alias: "guicheDelete",
    description: `Delete one guiche by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "guicID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                guicID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/guiche/:guicID",
    alias: "guicheGet",
    description: `Get one guiche`,
    requestFormat: "json",
    parameters: [
      {
        name: "guicID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                guicID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/guiche/:guicID",
    alias: "guicheUpdate",
    description: `Update guiche`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "guicID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                guicID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-action-types",
    alias: "incidentActionTypeList",
    description: `Lists all incident_action_types`,
    requestFormat: "json",
    parameters: [
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "iatyID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentActionTypeView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-action-types",
    alias: "incidentActionTypeCreate",
    description: `Create one incident_action_type`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                iatyID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-action-types/:iatyID",
    alias: "incidentActionTypeDelete",
    description: `Delete one incident_action_type by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "iatyID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                iatyID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-action-types/:iatyID",
    alias: "incidentActionTypeGet",
    description: `Get one incident_action_type`,
    requestFormat: "json",
    parameters: [
      {
        name: "iatyID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                iatyID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-action-types/:iatyID",
    alias: "incidentActionTypeUpdate",
    description: `Update incident_action_type`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "iatyID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                iatyID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-dispatchers",
    alias: "incidentDispatcherList",
    description: `Lists all incident_dispatchers`,
    requestFormat: "json",
    parameters: [
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "indiID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentDispatcherView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-dispatchers",
    alias: "incidentDispatcherCreate",
    description: `Create one incident_dispatcher`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                indiID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-dispatchers/:indiID",
    alias: "incidentDispatcherDelete",
    description: `Delete one incident_dispatcher by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "indiID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                indiID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-dispatchers/:indiID",
    alias: "incidentDispatcherGet",
    description: `Get one incident_dispatcher`,
    requestFormat: "json",
    parameters: [
      {
        name: "indiID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                indiID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-dispatchers/:indiID",
    alias: "incidentDispatcherUpdate",
    description: `Update incident_dispatcher`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "indiID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                indiID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-environments",
    alias: "incidentEnvironmentList",
    description: `Lists all incident_environments`,
    requestFormat: "json",
    parameters: [
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inenID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentEnvironmentView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-environments",
    alias: "incidentEnvironmentCreate",
    description: `Create one incident_environment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inenID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-environments/:inenID",
    alias: "incidentEnvironmentDelete",
    description: `Delete one incident_environment by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "inenID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inenID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-environments/:inenID",
    alias: "incidentEnvironmentGet",
    description: `Get one incident_environment`,
    requestFormat: "json",
    parameters: [
      {
        name: "inenID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inenID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-environments/:inenID",
    alias: "incidentEnvironmentUpdate",
    description: `Update incident_environment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "inenID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inenID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-establishments",
    alias: "incidentEstablishmentList",
    description: `Lists all incident_establishments`,
    requestFormat: "json",
    parameters: [
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inesID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentEstablishmentView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-establishments",
    alias: "incidentEstablishmentCreate",
    description: `Create one incident_establishment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inesID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-establishments/:inesID",
    alias: "incidentEstablishmentDelete",
    description: `Delete one incident_establishment by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "inesID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inesID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-establishments/:inesID",
    alias: "incidentEstablishmentGet",
    description: `Get one incident_establishment`,
    requestFormat: "json",
    parameters: [
      {
        name: "inesID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inesID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-establishments/:inesID",
    alias: "incidentEstablishmentUpdate",
    description: `Update incident_establishment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "inesID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inesID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-natures",
    alias: "incidentNatureList",
    description: `Lists all thematic axes`,
    requestFormat: "json",
    parameters: [
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "innaID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentNatureView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-natures",
    alias: "incidentNatureCreate",
    description: `Create one incident nature`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                innaID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-natures/:innaID",
    alias: "incidentNatureDelete",
    description: `Delete one incident nature by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "innaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                innaID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-natures/:innaID",
    alias: "incidentNatureGet",
    description: `Get one incident nature`,
    requestFormat: "json",
    parameters: [
      {
        name: "innaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                innaID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-natures/:innaID",
    alias: "incidentNatureUpdate",
    description: `Update incident nature`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "innaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                innaID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-origins",
    alias: "incidentOriginList",
    description: `Lists all incident_origins`,
    requestFormat: "json",
    parameters: [
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inorID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentOriginView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-origins",
    alias: "incidentOriginCreate",
    description: `Create one incident_origin`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inorID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-origins/:inorID",
    alias: "incidentOriginDelete",
    description: `Delete one incident_origin by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "inorID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inorID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-origins/:inorID",
    alias: "incidentOriginGet",
    description: `Get one incident_origin`,
    requestFormat: "json",
    parameters: [
      {
        name: "inorID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inorID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-origins/:inorID",
    alias: "incidentOriginUpdate",
    description: `Update incident_origin`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "inorID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inorID: z.number(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-programs",
    alias: "incidentProgramList",
    description: `Lists all incident_programs`,
    requestFormat: "json",
    parameters: [
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inprID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentProgramView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-programs",
    alias: "incidentProgramCreate",
    description: `Create one incident_program`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentProgramCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inprID: z.number(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-programs/:inprID",
    alias: "incidentProgramDelete",
    description: `Delete one incident_program by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "inprID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inprID: z.number(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-programs/:inprID",
    alias: "incidentProgramGet",
    description: `Get one incident_program`,
    requestFormat: "json",
    parameters: [
      {
        name: "inprID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inprID: z.number(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-programs/:inprID",
    alias: "incidentProgramUpdate",
    description: `Update incident_program`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentProgramCreate_Body,
      },
      {
        name: "inprID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inprID: z.number(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-response-locations",
    alias: "incidentResponseLocationList",
    description: `Lists all incident_response_locations`,
    requestFormat: "json",
    parameters: [
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "irloID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentResponseLocationView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-response-locations",
    alias: "incidentResponseLocationCreate",
    description: `Create one incident_response_location`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                irloID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-response-locations/:irloID",
    alias: "incidentResponseLocationDelete",
    description: `Delete one incident_response_location by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "irloID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                irloID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-response-locations/:irloID",
    alias: "incidentResponseLocationGet",
    description: `Get one incident_response_location`,
    requestFormat: "json",
    parameters: [
      {
        name: "irloID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                irloID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-response-locations/:irloID",
    alias: "incidentResponseLocationUpdate",
    description: `Update incident_response_location`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "irloID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                irloID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-status",
    alias: "incidentStatusList",
    description: `Lists all incident statuss`,
    requestFormat: "json",
    parameters: [
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "istaID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentStatus
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-status",
    alias: "incidentStatusCreate",
    description: `Create one incident status`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentStatusCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string().nullable() })
                      .partial()
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                istaID: z.number(),
                name: z.string(),
                reasons: z.array(z.string()).nullish(),
                requiredReason: z.boolean().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-status/:istaID",
    alias: "incidentStatusDelete",
    description: `Delete one incident status by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "istaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string().nullable() })
                      .partial()
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                istaID: z.number(),
                name: z.string(),
                reasons: z.array(z.string()).nullish(),
                requiredReason: z.boolean().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-status/:istaID",
    alias: "incidentStatusGet",
    description: `Get one incident status`,
    requestFormat: "json",
    parameters: [
      {
        name: "istaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string().nullable() })
                      .partial()
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                istaID: z.number(),
                name: z.string(),
                reasons: z.array(z.string()).nullish(),
                requiredReason: z.boolean().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-status/:istaID",
    alias: "incidentStatusUpdate",
    description: `Update incident status`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentStatusCreate_Body,
      },
      {
        name: "istaID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string().nullable() })
                      .partial()
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                istaID: z.number(),
                name: z.string(),
                reasons: z.array(z.string()).nullish(),
                requiredReason: z.boolean().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-subnatures",
    alias: "incidentSubnatureList",
    description: `Lists all incident_subnatures`,
    requestFormat: "json",
    parameters: [
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inbnID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "innaID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentSubnatureView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-subnatures",
    alias: "incidentSubnatureCreate",
    description: `Create one incident_subnature`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentSubnatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inbnID: z.number(),
                innaID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-subnatures/:inbnID",
    alias: "incidentSubnatureDelete",
    description: `Delete one incident_subnature by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "inbnID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inbnID: z.number(),
                innaID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-subnatures/:inbnID",
    alias: "incidentSubnatureGet",
    description: `Get one incident_subnature`,
    requestFormat: "json",
    parameters: [
      {
        name: "inbnID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inbnID: z.number(),
                innaID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-subnatures/:inbnID",
    alias: "incidentSubnatureUpdate",
    description: `Update incident_subnature`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentSubnatureCreate_Body,
      },
      {
        name: "inbnID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inbnID: z.number(),
                innaID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-subprograms",
    alias: "incidentSubprogramList",
    description: `Lists all incident_subprograms`,
    requestFormat: "json",
    parameters: [
      {
        name: "insuID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inprID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentSubprogramView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incident-subprograms",
    alias: "incidentSubprogramCreate",
    description: `Create one incident_subprogram`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentSubprogramCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inprID: z.number(),
                insuID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incident-subprograms/:insuID",
    alias: "incidentSubprogramDelete",
    description: `Delete one incident_subprogram by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "insuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inprID: z.number(),
                insuID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incident-subprograms/:insuID",
    alias: "incidentSubprogramGet",
    description: `Get one incident_subprogram`,
    requestFormat: "json",
    parameters: [
      {
        name: "insuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inprID: z.number(),
                insuID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incident-subprograms/:insuID",
    alias: "incidentSubprogramUpdate",
    description: `Update incident_subprogram`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentSubprogramCreate_Body,
      },
      {
        name: "insuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                inprID: z.number(),
                insuID: z.number(),
                name: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incidents",
    alias: "incidentList",
    description: `Lists all incidents`,
    requestFormat: "json",
    parameters: [
      {
        name: "natureName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "establishmentName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "dispatcherName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "subprogramName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "statusName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "actionTypeName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "inciID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "programName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "servicedAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "originName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "uid",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "incidentType",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "subnatureName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "environmentName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "servicedAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/incidents",
    alias: "incidentCreate",
    description: `Create one incident`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                actionTypeName: z.string(),
                address: z
                  .object({
                    city: z.string().nullish(),
                    complement: z.string().nullish(),
                    isVillage: z.boolean(),
                    latitude: z.number().nullish(),
                    longitude: z.number().nullish(),
                    neighborhood: z.string().nullish(),
                    number: z.string().nullish(),
                    state: z.string().nullish(),
                    street: z.string().nullish(),
                    zipCode: z.string().nullish(),
                  })
                  .passthrough()
                  .nullish(),
                agentID: z.string().nullish(),
                agentName: z.string(),
                agentRole: z.string().nullish(),
                arrivalAt: z.string().nullish(),
                buunID: z.number().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                depredations: z.array(
                  github_com_falqondev_PMSPDS_backend_service_Depredation
                ),
                description: z.string(),
                details: z
                  .object({
                    confidential: z.boolean().nullable(),
                    protocolNumber: z.string().nullable(),
                    relatorAttachment: z.string().nullable(),
                    relatorDescription: z.string().nullable(),
                    solution: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                dinaID: z.number().nullish(),
                dispatchNature: z.string(),
                dispatchStatus: z.string(),
                dispatchSubnature: z.string(),
                dispatchUnit: z.string(),
                dispatcherName: z.string(),
                distID: z.number().nullish(),
                disuID: z.number().nullish(),
                diunID: z.number().nullish(),
                environmentName: z.string().nullish(),
                establishmentName: z.string().nullish(),
                fieldContact: z
                  .object({
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                guicID: z.number().nullish(),
                guicheName: z.string(),
                iatyID: z.number().nullish(),
                ifdiID: z.number().nullish(),
                inbnID: z.number().nullish(),
                inciID: z.string(),
                incidentType: z.string(),
                incidentVehicles: z.array(
                  github_com_falqondev_PMSPDS_backend_service_IncidentVehicleView
                ),
                indeID: z.number().nullish(),
                indiID: z.number().nullish(),
                inenID: z.number().nullish(),
                inesID: z.number().nullish(),
                infdID: z.number().nullish(),
                ininID: z.number().nullish(),
                innaID: z.number().nullish(),
                inorID: z.number().nullish(),
                inprID: z.number().nullish(),
                inseID: z.number().nullish(),
                instID: z.string(),
                institutionName: z.string(),
                insuID: z.number().nullish(),
                involvedParties: z.array(
                  github_com_falqondev_PMSPDS_backend_service_InvolvedPartyView
                ),
                istaID: z.number(),
                natureName: z.string(),
                originName: z.string(),
                partnerInstID: z.string().nullish(),
                partnerInstitutionName: z.string(),
                programName: z.string(),
                progressionFromUid: z.string().nullish(),
                publicEquipmentName: z.string(),
                pueqID: z.number().nullish(),
                relatorCode: z.string().nullish(),
                relatorID: z.string().nullish(),
                relatorName: z.string(),
                relatorRole: z.string().nullish(),
                requester: z
                  .object({
                    email: z.string().nullable(),
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                    sex: z.string().nullable(),
                  })
                  .partial()
                  .passthrough()
                  .nullish(),
                sechID: z.number().nullish(),
                seizedItems: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SeizedItemView
                ),
                serviceChannelName: z.string(),
                servicedAt: z.string().nullish(),
                statusInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string().nullable() })
                      .partial()
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                statusName: z.string(),
                statusReason: z.string().nullish(),
                subnatureName: z.string(),
                subprogramName: z.string(),
                uid: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
                workOrderUid: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/incidents/:inciID",
    alias: "incidentDelete",
    description: `Delete one incident by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "inciID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                actionTypeName: z.string(),
                address: z
                  .object({
                    city: z.string().nullish(),
                    complement: z.string().nullish(),
                    isVillage: z.boolean(),
                    latitude: z.number().nullish(),
                    longitude: z.number().nullish(),
                    neighborhood: z.string().nullish(),
                    number: z.string().nullish(),
                    state: z.string().nullish(),
                    street: z.string().nullish(),
                    zipCode: z.string().nullish(),
                  })
                  .passthrough()
                  .nullish(),
                agentID: z.string().nullish(),
                agentName: z.string(),
                agentRole: z.string().nullish(),
                arrivalAt: z.string().nullish(),
                buunID: z.number().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                depredations: z.array(
                  github_com_falqondev_PMSPDS_backend_service_Depredation
                ),
                description: z.string(),
                details: z
                  .object({
                    confidential: z.boolean().nullable(),
                    protocolNumber: z.string().nullable(),
                    relatorAttachment: z.string().nullable(),
                    relatorDescription: z.string().nullable(),
                    solution: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                dinaID: z.number().nullish(),
                dispatchNature: z.string(),
                dispatchStatus: z.string(),
                dispatchSubnature: z.string(),
                dispatchUnit: z.string(),
                dispatcherName: z.string(),
                distID: z.number().nullish(),
                disuID: z.number().nullish(),
                diunID: z.number().nullish(),
                environmentName: z.string().nullish(),
                establishmentName: z.string().nullish(),
                fieldContact: z
                  .object({
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                guicID: z.number().nullish(),
                guicheName: z.string(),
                iatyID: z.number().nullish(),
                ifdiID: z.number().nullish(),
                inbnID: z.number().nullish(),
                inciID: z.string(),
                incidentType: z.string(),
                incidentVehicles: z.array(
                  github_com_falqondev_PMSPDS_backend_service_IncidentVehicleView
                ),
                indeID: z.number().nullish(),
                indiID: z.number().nullish(),
                inenID: z.number().nullish(),
                inesID: z.number().nullish(),
                infdID: z.number().nullish(),
                ininID: z.number().nullish(),
                innaID: z.number().nullish(),
                inorID: z.number().nullish(),
                inprID: z.number().nullish(),
                inseID: z.number().nullish(),
                instID: z.string(),
                institutionName: z.string(),
                insuID: z.number().nullish(),
                involvedParties: z.array(
                  github_com_falqondev_PMSPDS_backend_service_InvolvedPartyView
                ),
                istaID: z.number(),
                natureName: z.string(),
                originName: z.string(),
                partnerInstID: z.string().nullish(),
                partnerInstitutionName: z.string(),
                programName: z.string(),
                progressionFromUid: z.string().nullish(),
                publicEquipmentName: z.string(),
                pueqID: z.number().nullish(),
                relatorCode: z.string().nullish(),
                relatorID: z.string().nullish(),
                relatorName: z.string(),
                relatorRole: z.string().nullish(),
                requester: z
                  .object({
                    email: z.string().nullable(),
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                    sex: z.string().nullable(),
                  })
                  .partial()
                  .passthrough()
                  .nullish(),
                sechID: z.number().nullish(),
                seizedItems: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SeizedItemView
                ),
                serviceChannelName: z.string(),
                servicedAt: z.string().nullish(),
                statusInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string().nullable() })
                      .partial()
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                statusName: z.string(),
                statusReason: z.string().nullish(),
                subnatureName: z.string(),
                subprogramName: z.string(),
                uid: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
                workOrderUid: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incidents/:inciID",
    alias: "incidentGet",
    description: `Get one incident`,
    requestFormat: "json",
    parameters: [
      {
        name: "inciID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                actionTypeName: z.string(),
                address: z
                  .object({
                    city: z.string().nullish(),
                    complement: z.string().nullish(),
                    isVillage: z.boolean(),
                    latitude: z.number().nullish(),
                    longitude: z.number().nullish(),
                    neighborhood: z.string().nullish(),
                    number: z.string().nullish(),
                    state: z.string().nullish(),
                    street: z.string().nullish(),
                    zipCode: z.string().nullish(),
                  })
                  .passthrough()
                  .nullish(),
                agentID: z.string().nullish(),
                agentName: z.string(),
                agentRole: z.string().nullish(),
                arrivalAt: z.string().nullish(),
                buunID: z.number().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                depredations: z.array(
                  github_com_falqondev_PMSPDS_backend_service_Depredation
                ),
                description: z.string(),
                details: z
                  .object({
                    confidential: z.boolean().nullable(),
                    protocolNumber: z.string().nullable(),
                    relatorAttachment: z.string().nullable(),
                    relatorDescription: z.string().nullable(),
                    solution: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                dinaID: z.number().nullish(),
                dispatchNature: z.string(),
                dispatchStatus: z.string(),
                dispatchSubnature: z.string(),
                dispatchUnit: z.string(),
                dispatcherName: z.string(),
                distID: z.number().nullish(),
                disuID: z.number().nullish(),
                diunID: z.number().nullish(),
                environmentName: z.string().nullish(),
                establishmentName: z.string().nullish(),
                fieldContact: z
                  .object({
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                guicID: z.number().nullish(),
                guicheName: z.string(),
                iatyID: z.number().nullish(),
                ifdiID: z.number().nullish(),
                inbnID: z.number().nullish(),
                inciID: z.string(),
                incidentType: z.string(),
                incidentVehicles: z.array(
                  github_com_falqondev_PMSPDS_backend_service_IncidentVehicleView
                ),
                indeID: z.number().nullish(),
                indiID: z.number().nullish(),
                inenID: z.number().nullish(),
                inesID: z.number().nullish(),
                infdID: z.number().nullish(),
                ininID: z.number().nullish(),
                innaID: z.number().nullish(),
                inorID: z.number().nullish(),
                inprID: z.number().nullish(),
                inseID: z.number().nullish(),
                instID: z.string(),
                institutionName: z.string(),
                insuID: z.number().nullish(),
                involvedParties: z.array(
                  github_com_falqondev_PMSPDS_backend_service_InvolvedPartyView
                ),
                istaID: z.number(),
                natureName: z.string(),
                originName: z.string(),
                partnerInstID: z.string().nullish(),
                partnerInstitutionName: z.string(),
                programName: z.string(),
                progressionFromUid: z.string().nullish(),
                publicEquipmentName: z.string(),
                pueqID: z.number().nullish(),
                relatorCode: z.string().nullish(),
                relatorID: z.string().nullish(),
                relatorName: z.string(),
                relatorRole: z.string().nullish(),
                requester: z
                  .object({
                    email: z.string().nullable(),
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                    sex: z.string().nullable(),
                  })
                  .partial()
                  .passthrough()
                  .nullish(),
                sechID: z.number().nullish(),
                seizedItems: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SeizedItemView
                ),
                serviceChannelName: z.string(),
                servicedAt: z.string().nullish(),
                statusInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string().nullable() })
                      .partial()
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                statusName: z.string(),
                statusReason: z.string().nullish(),
                subnatureName: z.string(),
                subprogramName: z.string(),
                uid: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
                workOrderUid: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/incidents/:inciID",
    alias: "incidentUpdate",
    description: `Update incident`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentCreate_Body,
      },
      {
        name: "inciID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                actionTypeName: z.string(),
                address: z
                  .object({
                    city: z.string().nullish(),
                    complement: z.string().nullish(),
                    isVillage: z.boolean(),
                    latitude: z.number().nullish(),
                    longitude: z.number().nullish(),
                    neighborhood: z.string().nullish(),
                    number: z.string().nullish(),
                    state: z.string().nullish(),
                    street: z.string().nullish(),
                    zipCode: z.string().nullish(),
                  })
                  .passthrough()
                  .nullish(),
                agentID: z.string().nullish(),
                agentName: z.string(),
                agentRole: z.string().nullish(),
                arrivalAt: z.string().nullish(),
                buunID: z.number().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                depredations: z.array(
                  github_com_falqondev_PMSPDS_backend_service_Depredation
                ),
                description: z.string(),
                details: z
                  .object({
                    confidential: z.boolean().nullable(),
                    protocolNumber: z.string().nullable(),
                    relatorAttachment: z.string().nullable(),
                    relatorDescription: z.string().nullable(),
                    solution: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                dinaID: z.number().nullish(),
                dispatchNature: z.string(),
                dispatchStatus: z.string(),
                dispatchSubnature: z.string(),
                dispatchUnit: z.string(),
                dispatcherName: z.string(),
                distID: z.number().nullish(),
                disuID: z.number().nullish(),
                diunID: z.number().nullish(),
                environmentName: z.string().nullish(),
                establishmentName: z.string().nullish(),
                fieldContact: z
                  .object({
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                guicID: z.number().nullish(),
                guicheName: z.string(),
                iatyID: z.number().nullish(),
                ifdiID: z.number().nullish(),
                inbnID: z.number().nullish(),
                inciID: z.string(),
                incidentType: z.string(),
                incidentVehicles: z.array(
                  github_com_falqondev_PMSPDS_backend_service_IncidentVehicleView
                ),
                indeID: z.number().nullish(),
                indiID: z.number().nullish(),
                inenID: z.number().nullish(),
                inesID: z.number().nullish(),
                infdID: z.number().nullish(),
                ininID: z.number().nullish(),
                innaID: z.number().nullish(),
                inorID: z.number().nullish(),
                inprID: z.number().nullish(),
                inseID: z.number().nullish(),
                instID: z.string(),
                institutionName: z.string(),
                insuID: z.number().nullish(),
                involvedParties: z.array(
                  github_com_falqondev_PMSPDS_backend_service_InvolvedPartyView
                ),
                istaID: z.number(),
                natureName: z.string(),
                originName: z.string(),
                partnerInstID: z.string().nullish(),
                partnerInstitutionName: z.string(),
                programName: z.string(),
                progressionFromUid: z.string().nullish(),
                publicEquipmentName: z.string(),
                pueqID: z.number().nullish(),
                relatorCode: z.string().nullish(),
                relatorID: z.string().nullish(),
                relatorName: z.string(),
                relatorRole: z.string().nullish(),
                requester: z
                  .object({
                    email: z.string().nullable(),
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                    sex: z.string().nullable(),
                  })
                  .partial()
                  .passthrough()
                  .nullish(),
                sechID: z.number().nullish(),
                seizedItems: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SeizedItemView
                ),
                serviceChannelName: z.string(),
                servicedAt: z.string().nullish(),
                statusInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string().nullable() })
                      .partial()
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                statusName: z.string(),
                statusReason: z.string().nullish(),
                subnatureName: z.string(),
                subprogramName: z.string(),
                uid: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
                workOrderUid: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incidents/:inciID/documents",
    alias: "incidentDocumentList",
    description: `Lists all incident documents`,
    requestFormat: "json",
    parameters: [
      {
        name: "inciID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "doteID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "docuID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "infdID",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_IncidentDocumentView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/incidents/download",
    alias: "incidentDownload",
    description: `Get incident export file`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "servicedAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "uid",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "subprogramName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "originName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "statusName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "establishmentName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "subnatureName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "actionTypeName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inciID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "environmentName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "programName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "servicedAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "incidentType",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "dispatcherName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "natureName",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/institutions",
    alias: "institutionList",
    description: `Lists all institutions`,
    requestFormat: "json",
    parameters: [
      {
        name: "abbreviation",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "userID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_Institution
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/institutions",
    alias: "institutionCreate",
    description: `Create one institution`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: institutionCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                abbreviation: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                documentNumber: z.string(),
                documentType: z.string(),
                instID: z.string(),
                name: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/institutions/:instID",
    alias: "institutionDelete",
    description: `Delete one institution by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                abbreviation: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                documentNumber: z.string(),
                documentType: z.string(),
                instID: z.string(),
                name: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/institutions/:instID",
    alias: "institutionGet",
    description: `Get one institution`,
    requestFormat: "json",
    parameters: [
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                abbreviation: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                documentNumber: z.string(),
                documentType: z.string(),
                instID: z.string(),
                name: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/institutions/:instID",
    alias: "institutionUpdate",
    description: `Update institution`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: institutionCreate_Body,
      },
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                abbreviation: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                documentNumber: z.string(),
                documentType: z.string(),
                instID: z.string(),
                name: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/institutions/:instID/business-units",
    alias: "institutionUnitList",
    description: `Lists all business units for institution`,
    requestFormat: "json",
    parameters: [
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "buunID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_BusinessUnit
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/institutions/:instID/business-units",
    alias: "institutionUnitCreate",
    description: `Create one institution business unit`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: institutionUnitCreate_Body,
      },
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    city: z.string(),
                    country: z.string(),
                    neighborhood: z.string(),
                    number: z.string(),
                    state: z.string(),
                    street: z.string(),
                    zipCode: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                buunID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                name: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/institutions/:instID/business-units/:buunID",
    alias: "institutionUnitDelete",
    description: `Delete one business unit by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "buunID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    city: z.string(),
                    country: z.string(),
                    neighborhood: z.string(),
                    number: z.string(),
                    state: z.string(),
                    street: z.string(),
                    zipCode: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                buunID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                name: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/institutions/:instID/business-units/:buunID",
    alias: "institutionUnitGet",
    description: `Get one business unit`,
    requestFormat: "json",
    parameters: [
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "buunID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    city: z.string(),
                    country: z.string(),
                    neighborhood: z.string(),
                    number: z.string(),
                    state: z.string(),
                    street: z.string(),
                    zipCode: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                buunID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                name: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/institutions/:instID/business-units/:buunID",
    alias: "institutionUnitUpdate",
    description: `Update business unit`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: institutionUnitCreate_Body,
      },
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "buunID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    city: z.string(),
                    country: z.string(),
                    neighborhood: z.string(),
                    number: z.string(),
                    state: z.string(),
                    street: z.string(),
                    zipCode: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                buunID: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                name: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/institutions/:instID/users",
    alias: "institutionUserList",
    description: `Lists all institutions`,
    requestFormat: "json",
    parameters: [
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "userID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inusID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "keywords",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_InstitutionUserView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/institutions/:instID/users",
    alias: "institutionUserCreate",
    description: `Create one institution user`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: institutionUserCreate_Body,
      },
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                admittedAt: z.string().nullish(),
                buunID: z.number().nullish(),
                code: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                operationalCode: z.string().nullish(),
                phone: z.string(),
                sex: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/institutions/:instID/users/:inusID",
    alias: "institutionUserDelete",
    description: `Delete one institution user by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "inusID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                admittedAt: z.string().nullish(),
                buunID: z.number().nullish(),
                code: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                operationalCode: z.string().nullish(),
                phone: z.string(),
                sex: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/institutions/:instID/users/:inusID",
    alias: "institutionUserGet",
    description: `Get one institution user`,
    requestFormat: "json",
    parameters: [
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "inusID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                admittedAt: z.string().nullish(),
                buunID: z.number().nullish(),
                code: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                operationalCode: z.string().nullish(),
                phone: z.string(),
                sex: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/institutions/:instID/users/:inusID",
    alias: "institutionUserUpdate",
    description: `Update institution`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: institutionUserUpdate_Body,
      },
      {
        name: "instID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "inusID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                admittedAt: z.string().nullish(),
                buunID: z.number().nullish(),
                code: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                operationalCode: z.string().nullish(),
                phone: z.string(),
                sex: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/one-time-login",
    alias: "oneTimeLoginCreate",
    description: `Create new oneTimeLogin`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: oneTimeLoginCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                otloID: z.number(),
                token: z.string(),
                userID: z.string(),
                validUntil: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/permission/:acplID/action-plan",
    alias: "actionPlanPermissionGet",
    description: `Get action plan permission`,
    requestFormat: "json",
    parameters: [
      {
        name: "acplID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "readonly",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.boolean(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/permission/:actiID/action",
    alias: "actionPermissionGet",
    description: `Get action permission`,
    requestFormat: "json",
    parameters: [
      {
        name: "actiID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "readonly",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.boolean(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/permission/:bumeID/business-metric",
    alias: "businessMetricPermissionGet",
    description: `Get business metric permission`,
    requestFormat: "json",
    parameters: [
      {
        name: "bumeID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "readonly",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.boolean(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/permission/:progID/programs",
    alias: "programPermissionGet",
    description: `Get program permission`,
    requestFormat: "json",
    parameters: [
      {
        name: "progID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "readonly",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.boolean(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/permission/:projID/projects",
    alias: "projectPermissionGet",
    description: `Get project permission`,
    requestFormat: "json",
    parameters: [
      {
        name: "projID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "readonly",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.boolean(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/physical-conditions",
    alias: "physicalConditionList",
    description: `Lists all physical_conditions`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "phcoID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_PhysicalConditionView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/physical-conditions",
    alias: "physicalConditionCreate",
    description: `Create one physical_condition`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                phcoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/physical-conditions/:phcoID",
    alias: "physicalConditionDelete",
    description: `Delete one physical_condition by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "phcoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                phcoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/physical-conditions/:phcoID",
    alias: "physicalConditionGet",
    description: `Get one physical_condition`,
    requestFormat: "json",
    parameters: [
      {
        name: "phcoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                phcoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/physical-conditions/:phcoID",
    alias: "physicalConditionUpdate",
    description: `Update physical_condition`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "phcoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                phcoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/plans",
    alias: "plansList",
    description: `Lists all plans`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "planID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_PlanView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/plans",
    alias: "plansCreate",
    description: `Create one plan`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: plansCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                planID: z.string(),
                planUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ProjetcUserView
                ),
                thaxID: z.array(z.number()),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/plans/:planID",
    alias: "plansGet",
    description: `Get one plan`,
    requestFormat: "json",
    parameters: [
      {
        name: "planID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                planID: z.string(),
                planUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ProjetcUserView
                ),
                thaxID: z.array(z.number()),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/plans/:planID",
    alias: "plansUpdate",
    description: `Update one plan`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: plansCreate_Body,
      },
      {
        name: "planID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                instID: z.string(),
                inusID: z.number(),
                name: z.string(),
                planID: z.string(),
                planUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ProjetcUserView
                ),
                thaxID: z.array(z.number()),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/programs",
    alias: "programList",
    description: `Lists all programs`,
    requestFormat: "json",
    parameters: [
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "progID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "userName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "planID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "content",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "periodStart[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "inCharge",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "periodEnd[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "thaxID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "status",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "periodStart[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "periodEnd[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inusID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ProgramView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/programs",
    alias: "programCreate",
    description: `Create new program`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: programCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                axes: z.array(z.number()),
                budgets: z.array(
                  z
                    .object({
                      actual: z.number(),
                      budgID: z.string(),
                      busoID: z.number(),
                      predicted: z.number(),
                      received: z.number(),
                    })
                    .passthrough()
                ),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                institutionUserAvatar: z.string().nullish(),
                institutionUserEmail: z.string().nullish(),
                institutionUserName: z.string(),
                institutions: z.array(z.string()),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                planID: z.string(),
                progID: z.string(),
                programUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ProgramUserView
                ),
                projectCount: z.number(),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/programs/:progID",
    alias: "programDelete",
    description: `Delete one program by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "progID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                inusID: z.number(),
                name: z.string(),
                planID: z.string(),
                progID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/programs/:progID",
    alias: "programGet",
    description: `Get one program`,
    requestFormat: "json",
    parameters: [
      {
        name: "progID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                axes: z.array(z.number()),
                budgets: z.array(
                  z
                    .object({
                      actual: z.number(),
                      budgID: z.string(),
                      busoID: z.number(),
                      predicted: z.number(),
                      received: z.number(),
                    })
                    .passthrough()
                ),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                institutionUserAvatar: z.string().nullish(),
                institutionUserEmail: z.string().nullish(),
                institutionUserName: z.string(),
                institutions: z.array(z.string()),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                planID: z.string(),
                progID: z.string(),
                programUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ProgramUserView
                ),
                projectCount: z.number(),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/programs/:progID",
    alias: "programUpdate",
    description: `Update one program`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: programCreate_Body,
      },
      {
        name: "progID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                axes: z.array(z.number()),
                budgets: z.array(
                  z
                    .object({
                      actual: z.number(),
                      budgID: z.string(),
                      busoID: z.number(),
                      predicted: z.number(),
                      received: z.number(),
                    })
                    .passthrough()
                ),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                institutionUserAvatar: z.string().nullish(),
                institutionUserEmail: z.string().nullish(),
                institutionUserName: z.string(),
                institutions: z.array(z.string()),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                planID: z.string(),
                progID: z.string(),
                programUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ProgramUserView
                ),
                projectCount: z.number(),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/programs/:progID/history/budget",
    alias: "programBudgetHistory",
    description: `Lists all program budget`,
    requestFormat: "json",
    parameters: [
      {
        name: "progID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ProgramBudgetHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/programs/:progID/history/period",
    alias: "programPeriodHistory",
    description: `Lists all program periods`,
    requestFormat: "json",
    parameters: [
      {
        name: "progID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ProgramPeriodHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/programs/:progID/history/status",
    alias: "programStatusHistory",
    description: `Lists all program status`,
    requestFormat: "json",
    parameters: [
      {
        name: "progID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ProgramStatusHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/projects",
    alias: "projectList",
    description: `Lists all projects`,
    requestFormat: "json",
    parameters: [
      {
        name: "planID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "inusID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "inCharge",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "content",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "thaxID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "progID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "projID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ProjectView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/projects",
    alias: "projectCreate",
    description: `Create new project`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: projectCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                actionPlanCount: z.number(),
                attachment: z.string().nullish(),
                axes: z.array(z.number()),
                budgets: z.array(
                  z
                    .object({
                      actual: z.number(),
                      budgID: z.string(),
                      busoID: z.number(),
                      predicted: z.number(),
                      received: z.number(),
                    })
                    .passthrough()
                ),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                institutionUserAvatar: z.string().nullish(),
                institutionUserEmail: z.string().nullish(),
                institutionUserName: z.string(),
                institutions: z.array(z.string()),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                planID: z.string(),
                progID: z.string().nullish(),
                projID: z.string(),
                projectUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ProjetcUserView
                ),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/projects/:projID",
    alias: "projectDelete",
    description: `Delete one project by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "projID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                attachment: z.string().nullish(),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                inusID: z.number(),
                name: z.string(),
                planID: z.string(),
                progID: z.string().nullish(),
                projID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/projects/:projID",
    alias: "projectGet",
    description: `Get one project`,
    requestFormat: "json",
    parameters: [
      {
        name: "projID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                actionPlanCount: z.number(),
                attachment: z.string().nullish(),
                axes: z.array(z.number()),
                budgets: z.array(
                  z
                    .object({
                      actual: z.number(),
                      budgID: z.string(),
                      busoID: z.number(),
                      predicted: z.number(),
                      received: z.number(),
                    })
                    .passthrough()
                ),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                institutionUserAvatar: z.string().nullish(),
                institutionUserEmail: z.string().nullish(),
                institutionUserName: z.string(),
                institutions: z.array(z.string()),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                planID: z.string(),
                progID: z.string().nullish(),
                projID: z.string(),
                projectUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ProjetcUserView
                ),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/projects/:projID",
    alias: "projectUpdate",
    description: `Update one project`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: projectCreate_Body,
      },
      {
        name: "projID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                actionPlanCount: z.number(),
                attachment: z.string().nullish(),
                axes: z.array(z.number()),
                budgets: z.array(
                  z
                    .object({
                      actual: z.number(),
                      budgID: z.string(),
                      busoID: z.number(),
                      predicted: z.number(),
                      received: z.number(),
                    })
                    .passthrough()
                ),
                content: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                institutionUserAvatar: z.string().nullish(),
                institutionUserEmail: z.string().nullish(),
                institutionUserName: z.string(),
                institutions: z.array(z.string()),
                inusID: z.number(),
                name: z.string(),
                period: z
                  .object({
                    endAt: z.string(),
                    oppeID: z.number(),
                    startAt: z.string(),
                  })
                  .passthrough(),
                planID: z.string(),
                progID: z.string().nullish(),
                projID: z.string(),
                projectUsers: z.array(
                  github_com_falqondev_PMSPDS_backend_service_ProjetcUserView
                ),
                status: z.string(),
                thematicAxisInfo: z.array(
                  z
                    .object({
                      displayOptions: z
                        .object({ color: z.string() })
                        .passthrough()
                        .nullish(),
                      thaxID: z.number(),
                    })
                    .passthrough()
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/projects/:projID/history/budget",
    alias: "projectBudgetHistory",
    description: `Lists all project budget`,
    requestFormat: "json",
    parameters: [
      {
        name: "projID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ProjectBudgetHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/projects/:projID/history/period",
    alias: "projectPeriodHistory",
    description: `Lists all project periods`,
    requestFormat: "json",
    parameters: [
      {
        name: "projID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ProjectPeriodHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/projects/:projID/history/status",
    alias: "projectStatusHistory",
    description: `Lists all project status`,
    requestFormat: "json",
    parameters: [
      {
        name: "projID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ProjectStatusHistory
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/public-equipments",
    alias: "publicEquipmentList",
    description: `Lists all public_equipments`,
    requestFormat: "json",
    parameters: [
      {
        name: "pueqID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_PublicEquipmentView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/public-equipments",
    alias: "publicEquipmentCreate",
    description: `Create one public_equipment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                pueqID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/public-equipments/:pueqID",
    alias: "publicEquipmentDelete",
    description: `Delete one public_equipment by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "pueqID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                pueqID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/public-equipments/:pueqID",
    alias: "publicEquipmentGet",
    description: `Get one public_equipment`,
    requestFormat: "json",
    parameters: [
      {
        name: "pueqID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                pueqID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/public-equipments/:pueqID",
    alias: "publicEquipmentUpdate",
    description: `Update public_equipment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "pueqID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                pueqID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/requesting-company",
    alias: "requestingCompanyList",
    description: `Lists all requesting_companys`,
    requestFormat: "json",
    parameters: [
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "recoID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_RequestingCompanyView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/requesting-company",
    alias: "requestingCompanyCreate",
    description: `Create one requesting_company`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                recoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/requesting-company/:recoID",
    alias: "requestingCompanyDelete",
    description: `Delete one requesting_company by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "recoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                recoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/requesting-company/:recoID",
    alias: "requestingCompanyGet",
    description: `Get one requesting_company`,
    requestFormat: "json",
    parameters: [
      {
        name: "recoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                recoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/requesting-company/:recoID",
    alias: "requestingCompanyUpdate",
    description: `Update requesting_company`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "recoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                recoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/service-channel",
    alias: "serviceChannelList",
    description: `Lists all service_channels`,
    requestFormat: "json",
    parameters: [
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "sechID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ServiceChannelView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/service-channel",
    alias: "serviceChannelCreate",
    description: `Create one service_channel`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                sechID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/service-channel/:sechID",
    alias: "serviceChannelDelete",
    description: `Delete one service_channel by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "sechID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                sechID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/service-channel/:sechID",
    alias: "serviceChannelGet",
    description: `Get one service_channel`,
    requestFormat: "json",
    parameters: [
      {
        name: "sechID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                sechID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/service-channel/:sechID",
    alias: "serviceChannelUpdate",
    description: `Update service_channel`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "sechID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                sechID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/survey-form-templates",
    alias: "surveyTemplatesCreate",
    description: `Create one survey form template`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: documentTemplateCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({ doteID: z.string(), suftID: z.number() })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/survey-responses",
    alias: "surveyResponseList",
    description: `List survey response`,
    requestFormat: "json",
    parameters: [
      {
        name: "submitted",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "survID",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_SurveyResponseListing
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/survey-responses",
    alias: "surveyResponseCreate",
    description: `Create one survey response`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: surveyResponseCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                clientDevice: z.string(),
                clientIP: z.string(),
                clientUID: z.string(),
                createdAt: z.string(),
                docuID: z.string(),
                doteID: z.string(),
                metadata: z.unknown(),
                sureID: z.number(),
                sursID: z.string(),
                survID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/survey-responses/download/:survID",
    alias: "surveyResponseDownload",
    description: `Get answer export file`,
    requestFormat: "json",
    parameters: [
      {
        name: "survID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "fileName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "submitted",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/survey-responses/my",
    alias: "surveyMyResponseList",
    description: `List survey response`,
    requestFormat: "json",
    parameters: [
      {
        name: "submitted",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "survID",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_SurveyResponseListing
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/survey-responses/my/:sursID",
    alias: "surveyMyResponseGet",
    description: `Get one survey`,
    requestFormat: "json",
    parameters: [
      {
        name: "sursID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                name: z.string(),
                responses: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SurveyResponseData
                ),
                submittedAt: z.string().nullish(),
                sursID: z.string(),
                survID: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/survey/session",
    alias: "surveyCreateSession",
    description: `Create new survey session`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ survID: z.string() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                submittedAt: z.string().nullish(),
                sursID: z.string(),
                survID: z.string(),
                userID: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/survey/submit",
    alias: "surveySubmit",
    description: `submit response`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ sursID: z.string() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/surveys",
    alias: "surveyList",
    description: `Lists all surveys`,
    requestFormat: "json",
    parameters: [
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "survID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "slug",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "active",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "title",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(github_com_falqondev_PMSPDS_backend_service_Survey),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/surveys",
    alias: "surveyCreate",
    description: `Create one survey`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: surveyCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                activeAt: z.string().nullish(),
                allowedUsers: z.array(z.string()),
                answers: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                descriptionFinished: z.string(),
                displayOptions: z.unknown(),
                drafts: z.number(),
                slug: z.string(),
                survID: z.string(),
                surveyForms: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SurveyForm
                ),
                title: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/surveys/:survID",
    alias: "surveyDelete",
    description: `Delete one survey by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "survID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                activeAt: z.string().nullish(),
                allowedUsers: z.array(z.string()),
                answers: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                descriptionFinished: z.string(),
                displayOptions: z.unknown(),
                drafts: z.number(),
                slug: z.string(),
                survID: z.string(),
                surveyForms: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SurveyForm
                ),
                title: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/surveys/:survID",
    alias: "surveyGet",
    description: `Get one survey`,
    requestFormat: "json",
    parameters: [
      {
        name: "survID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                activeAt: z.string().nullish(),
                allowedUsers: z.array(z.string()),
                answers: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                descriptionFinished: z.string(),
                displayOptions: z.unknown(),
                drafts: z.number(),
                slug: z.string(),
                survID: z.string(),
                surveyForms: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SurveyForm
                ),
                title: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/surveys/:survID",
    alias: "surveyUpdate",
    description: `Update survey`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: surveyCreate_Body,
      },
      {
        name: "survID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                activeAt: z.string().nullish(),
                allowedUsers: z.array(z.string()),
                answers: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                descriptionFinished: z.string(),
                displayOptions: z.unknown(),
                drafts: z.number(),
                slug: z.string(),
                survID: z.string(),
                surveyForms: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SurveyForm
                ),
                title: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/surveys/my",
    alias: "surveyMyList",
    description: `List my polls`,
    requestFormat: "json",
    parameters: [
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "survID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "slug",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "active",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "title",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_MySurvey
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/surveys/my/:survID",
    alias: "surveyMyGet",
    description: `Get one survey`,
    requestFormat: "json",
    parameters: [
      {
        name: "survID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                activeAt: z.string().nullish(),
                answers: z.number(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                descriptionFinished: z.string(),
                displayOptions: z.unknown(),
                drafts: z.number(),
                slug: z.string(),
                survID: z.string(),
                surveyForms: z.array(
                  github_com_falqondev_PMSPDS_backend_service_SurveyForm
                ),
                title: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/term/accept",
    alias: "termAccept",
    description: `Accept a term`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ termID: z.number() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                accepted: z.boolean(),
                termID: z.number(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/term/pending",
    alias: "termGetPending",
    description: `get all pending terms`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                terms: z.array(
                  github_com_falqondev_PMSPDS_backend_service_PendingTerm
                ),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/terms",
    alias: "termList",
    description: `Lists all terms`,
    requestFormat: "json",
    parameters: [
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "slug",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "title",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "active",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(github_com_falqondev_PMSPDS_backend_service_Term),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/terms",
    alias: "termCreate",
    description: `Create one term`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: termCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                slug: z.string(),
                termID: z.number(),
                title: z.string(),
                type: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/terms/:termID",
    alias: "termDelete",
    description: `Delete one term by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "termID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                slug: z.string(),
                termID: z.number(),
                title: z.string(),
                type: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/terms/:termID",
    alias: "termGet",
    description: `Get one term`,
    requestFormat: "json",
    parameters: [
      {
        name: "termID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                slug: z.string(),
                termID: z.number(),
                title: z.string(),
                type: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/terms/:termID",
    alias: "termUpdate",
    description: `Update term`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: termCreate_Body,
      },
      {
        name: "termID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                slug: z.string(),
                termID: z.number(),
                title: z.string(),
                type: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/thematic-axes",
    alias: "thematicAxisList",
    description: `Lists all thematic axes`,
    requestFormat: "json",
    parameters: [
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "planID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "thaxID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_ThematicAxis
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/thematic-axes",
    alias: "thematicAxisCreate",
    description: `Create one thematic axis`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: thematicAxisCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                name: z.string(),
                thaxID: z.number(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/thematic-axes/:thaxID",
    alias: "thematicAxisDelete",
    description: `Delete one thematic axis by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "thaxID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                name: z.string(),
                thaxID: z.number(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/thematic-axes/:thaxID",
    alias: "thematicAxisGet",
    description: `Get one thematic axis`,
    requestFormat: "json",
    parameters: [
      {
        name: "thaxID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                name: z.string(),
                thaxID: z.number(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/thematic-axes/:thaxID",
    alias: "thematicAxisUpdate",
    description: `Update thematic axis`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: thematicAxisCreate_Body,
      },
      {
        name: "thaxID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                name: z.string(),
                thaxID: z.number(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/urban-equipments",
    alias: "urbanEquipmentList",
    description: `Lists all urban_equipments`,
    requestFormat: "json",
    parameters: [
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "ureqID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_UrbanEquipmentView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/urban-equipments",
    alias: "urbanEquipmentCreate",
    description: `Create one urban_equipment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                ureqID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/urban-equipments/:ureqID",
    alias: "urbanEquipmentDelete",
    description: `Delete one urban_equipment by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "ureqID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                ureqID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/urban-equipments/:ureqID",
    alias: "urbanEquipmentGet",
    description: `Get one urban_equipment`,
    requestFormat: "json",
    parameters: [
      {
        name: "ureqID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                ureqID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/urban-equipments/:ureqID",
    alias: "urbanEquipmentUpdate",
    description: `Update urban_equipment`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "ureqID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                ureqID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/user-roles",
    alias: "userRoleCreate",
    description: `Create new user userRole`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: userRoleCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({ roles: z.array(z.string()), userID: z.string() })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/user-roles",
    alias: "userRoleUpdate",
    description: `Update one userRole`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: userRoleCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({ roles: z.array(z.string()), userID: z.string() })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/users",
    alias: "userList",
    description: `Lists all users`,
    requestFormat: "json",
    parameters: [
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "userID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "deleted",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "applID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "email",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "keywords",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "aproID",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_UserInformation
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/users",
    alias: "userCreate",
    description: `Create new user`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: userCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                authenticationProvider: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                roles: z.array(z.string()),
                userID: z.string(),
                userInstitutions: z.array(
                  github_com_falqondev_PMSPDS_backend_service_UserInstitutions
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/users/:userID",
    alias: "userDelete",
    description: `Delete one user`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "userID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                authenticationProvider: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                roles: z.array(z.string()),
                userID: z.string(),
                userInstitutions: z.array(
                  github_com_falqondev_PMSPDS_backend_service_UserInstitutions
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/users/:userID",
    alias: "userGet",
    description: `Get one user by userID`,
    requestFormat: "json",
    parameters: [
      {
        name: "userID",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                authenticationProvider: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                roles: z.array(z.string()),
                userID: z.string(),
                userInstitutions: z.array(
                  github_com_falqondev_PMSPDS_backend_service_UserInstitutions
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "patch",
    path: "/api/users/:userID",
    alias: "userUpdate",
    description: `Update one user`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: userUpdate_Body,
      },
      {
        name: "userID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                authenticationProvider: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                roles: z.array(z.string()),
                userID: z.string(),
                userInstitutions: z.array(
                  github_com_falqondev_PMSPDS_backend_service_UserInstitutions
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/users/:userID/enable",
    alias: "userEnable",
    description: `Enable a deleted user`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "userID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                authenticationProvider: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                roles: z.array(z.string()),
                userID: z.string(),
                userInstitutions: z.array(
                  github_com_falqondev_PMSPDS_backend_service_UserInstitutions
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/vulnerable-populations",
    alias: "vulnerablePopulationList",
    description: `Lists all vulnerable_populations`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "vupoID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_VulnerablePopulationView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/vulnerable-populations",
    alias: "vulnerablePopulationCreate",
    description: `Create one vulnerable_population`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                vupoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/vulnerable-populations/:vupoID",
    alias: "vulnerablePopulationDelete",
    description: `Delete one vulnerable_population by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "vupoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                vupoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/vulnerable-populations/:vupoID",
    alias: "vulnerablePopulationGet",
    description: `Get one vulnerable_population`,
    requestFormat: "json",
    parameters: [
      {
        name: "vupoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                vupoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/vulnerable-populations/:vupoID",
    alias: "vulnerablePopulationUpdate",
    description: `Update vulnerable_population`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "vupoID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                vupoID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-classifications",
    alias: "workOrderClassificationList",
    description: `Lists all work_order_classifications`,
    requestFormat: "json",
    parameters: [
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "woclID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_WorkOrderClassificationView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/work-order-classifications",
    alias: "workOrderClassificationCreate",
    description: `Create one work_order_classification`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                woclID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/work-order-classifications/:woclID",
    alias: "workOrderClassificationDelete",
    description: `Delete one work_order_classification by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "woclID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                woclID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-classifications/:woclID",
    alias: "workOrderClassificationGet",
    description: `Get one work_order_classification`,
    requestFormat: "json",
    parameters: [
      {
        name: "woclID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                woclID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/work-order-classifications/:woclID",
    alias: "workOrderClassificationUpdate",
    description: `Update work_order_classification`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "woclID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                woclID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-programs",
    alias: "workOrderProgramList",
    description: `Lists all work_order_programs`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "woprID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_WorkOrderProgramView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/work-order-programs",
    alias: "workOrderProgramCreate",
    description: `Create one work_order_program`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentProgramCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                name: z.string(),
                woprID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/work-order-programs/:woprID",
    alias: "workOrderProgramDelete",
    description: `Delete one work_order_program by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "woprID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                name: z.string(),
                woprID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-programs/:woprID",
    alias: "workOrderProgramGet",
    description: `Get one work_order_program`,
    requestFormat: "json",
    parameters: [
      {
        name: "woprID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                name: z.string(),
                woprID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/work-order-programs/:woprID",
    alias: "workOrderProgramUpdate",
    description: `Update work_order_program`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: incidentProgramCreate_Body,
      },
      {
        name: "woprID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string(),
                institutionName: z.string().nullish(),
                name: z.string(),
                woprID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-requester-functions",
    alias: "workOrderRequesterFunctionList",
    description: `Lists all work_order_requester_functions`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "worfID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_WorkOrderRequesterFunctionView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/work-order-requester-functions",
    alias: "workOrderRequesterFunctionCreate",
    description: `Create one work_order_requester_function`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                worfID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/work-order-requester-functions/:worfID",
    alias: "workOrderRequesterFunctionDelete",
    description: `Delete one work_order_requester_function by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "worfID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                worfID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-requester-functions/:worfID",
    alias: "workOrderRequesterFunctionGet",
    description: `Get one work_order_requester_function`,
    requestFormat: "json",
    parameters: [
      {
        name: "worfID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                worfID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/work-order-requester-functions/:worfID",
    alias: "workOrderRequesterFunctionUpdate",
    description: `Update work_order_requester_function`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "worfID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                worfID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-requester-types",
    alias: "workOrderRequesterTypeList",
    description: `Lists all work_order_requester_types`,
    requestFormat: "json",
    parameters: [
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "includeRemoved",
        type: "Query",
        schema: z.boolean().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "wortID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_WorkOrderRequesterTypeView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/work-order-requester-types",
    alias: "workOrderRequesterTypeCreate",
    description: `Create one work_order_requester_type`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                wortID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/work-order-requester-types/:wortID",
    alias: "workOrderRequesterTypeDelete",
    description: `Delete one work_order_requester_type by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "wortID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                wortID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-requester-types/:wortID",
    alias: "workOrderRequesterTypeGet",
    description: `Get one work_order_requester_type`,
    requestFormat: "json",
    parameters: [
      {
        name: "wortID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                wortID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/work-order-requester-types/:wortID",
    alias: "workOrderRequesterTypeUpdate",
    description: `Update work_order_requester_type`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: dispatchNatureCreate_Body,
      },
      {
        name: "wortID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                instID: z.string().nullish(),
                institutionName: z.string().nullish(),
                name: z.string(),
                wortID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-status",
    alias: "workOrderStatusList",
    description: `Lists all work_order_statuss`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "wostID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_WorkOrderStatusView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/work-order-status",
    alias: "workOrderStatusCreate",
    description: `Create one work_order_status`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: workOrderStatusCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                name: z.string(),
                reasons: z.array(z.string()).nullish(),
                requiredReason: z.boolean().nullish(),
                wostID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/work-order-status/:wostID",
    alias: "workOrderStatusDelete",
    description: `Delete one work_order_status by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "wostID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                name: z.string(),
                reasons: z.array(z.string()).nullish(),
                requiredReason: z.boolean().nullish(),
                wostID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-status/:wostID",
    alias: "workOrderStatusGet",
    description: `Get one work_order_status`,
    requestFormat: "json",
    parameters: [
      {
        name: "wostID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                name: z.string(),
                reasons: z.array(z.string()).nullish(),
                requiredReason: z.boolean().nullish(),
                wostID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/work-order-status/:wostID",
    alias: "workOrderStatusUpdate",
    description: `Update work_order_status`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: workOrderStatusCreate_Body,
      },
      {
        name: "wostID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                info: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                name: z.string(),
                reasons: z.array(z.string()).nullish(),
                requiredReason: z.boolean().nullish(),
                wostID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-subprograms",
    alias: "workOrderSubprogramList",
    description: `Lists all work_order_subprograms`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "wosuID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "woprID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_WorkOrderSubprogramView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/work-order-subprograms",
    alias: "workOrderSubprogramCreate",
    description: `Create one work_order_subprogram`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: workOrderSubprogramCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                name: z.string(),
                woprID: z.number(),
                wosuID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/work-order-subprograms/:wosuID",
    alias: "workOrderSubprogramDelete",
    description: `Delete one work_order_subprogram by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "wosuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                name: z.string(),
                woprID: z.number(),
                wosuID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-order-subprograms/:wosuID",
    alias: "workOrderSubprogramGet",
    description: `Get one work_order_subprogram`,
    requestFormat: "json",
    parameters: [
      {
        name: "wosuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                name: z.string(),
                woprID: z.number(),
                wosuID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/work-order-subprograms/:wosuID",
    alias: "workOrderSubprogramUpdate",
    description: `Update work_order_subprogram`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "wosuID",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                name: z.string(),
                woprID: z.number(),
                wosuID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-orders",
    alias: "workOrderList",
    description: `Lists all work_orders`,
    requestFormat: "json",
    parameters: [
      {
        name: "urbanEquipmentName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "requesterType",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "programName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "requesterRole",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "requesterCompanyName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "requesterName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "endAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "woorID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "uid",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "startAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "endAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "uIDs",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "subprogramName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "status",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "startAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "classification",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_WorkOrderView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/work-orders",
    alias: "workOrderCreate",
    description: `Create one work_order`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: workOrderCreate_Body,
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    city: z.string().nullable(),
                    complement: z.string().nullable(),
                    latitude: z.number().nullable(),
                    longitude: z.number().nullable(),
                    neighborhood: z.string().nullable(),
                    number: z.string().nullable(),
                    state: z.string().nullable(),
                    street: z.string().nullable(),
                    zipCode: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                attachment: z.string(),
                classification: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                endAt: z.string().nullish(),
                event: z.string(),
                extraordinaryEffective: z.number(),
                fieldAgents: z.array(
                  github_com_falqondev_PMSPDS_backend_service_FieldAgentInfo
                ),
                generalEffective: z.number(),
                instID: z.string(),
                institutionName: z.string(),
                ordinaryEffective: z.number(),
                planningIdentifier: z.string(),
                programName: z.string(),
                recoID: z.number().nullish(),
                requester: z
                  .object({
                    documentNumber: z.string().nullable(),
                    documentType: z.string().nullable(),
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                requesterCompanyName: z.string().nullish(),
                requesterRole: z.string(),
                requesterType: z.string(),
                startAt: z.string().nullish(),
                status: z.string(),
                statusInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                statusReason: z.string().nullish(),
                subprogramName: z.string(),
                uid: z.string(),
                updatedAt: z.string(),
                urbanEquipmentName: z.string(),
                ureqID: z.number().nullish(),
                userID: z.string(),
                woclID: z.number().nullish(),
                wodoID: z.number().nullish(),
                wofaID: z.number().nullish(),
                woorID: z.string(),
                woprID: z.number().nullish(),
                worfID: z.number().nullish(),
                wortID: z.number().nullish(),
                wostID: z.number(),
                wosuID: z.number().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "delete",
    path: "/api/work-orders/:woorID",
    alias: "workOrderDelete",
    description: `Delete one work_order by id`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "woorID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    city: z.string().nullable(),
                    complement: z.string().nullable(),
                    latitude: z.number().nullable(),
                    longitude: z.number().nullable(),
                    neighborhood: z.string().nullable(),
                    number: z.string().nullable(),
                    state: z.string().nullable(),
                    street: z.string().nullable(),
                    zipCode: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                attachment: z.string(),
                classification: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                endAt: z.string().nullish(),
                event: z.string(),
                extraordinaryEffective: z.number(),
                fieldAgents: z.array(
                  github_com_falqondev_PMSPDS_backend_service_FieldAgentInfo
                ),
                generalEffective: z.number(),
                instID: z.string(),
                institutionName: z.string(),
                ordinaryEffective: z.number(),
                planningIdentifier: z.string(),
                programName: z.string(),
                recoID: z.number().nullish(),
                requester: z
                  .object({
                    documentNumber: z.string().nullable(),
                    documentType: z.string().nullable(),
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                requesterCompanyName: z.string().nullish(),
                requesterRole: z.string(),
                requesterType: z.string(),
                startAt: z.string().nullish(),
                status: z.string(),
                statusInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                statusReason: z.string().nullish(),
                subprogramName: z.string(),
                uid: z.string(),
                updatedAt: z.string(),
                urbanEquipmentName: z.string(),
                ureqID: z.number().nullish(),
                userID: z.string(),
                woclID: z.number().nullish(),
                wodoID: z.number().nullish(),
                wofaID: z.number().nullish(),
                woorID: z.string(),
                woprID: z.number().nullish(),
                worfID: z.number().nullish(),
                wortID: z.number().nullish(),
                wostID: z.number(),
                wosuID: z.number().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-orders/:woorID",
    alias: "workOrderGet",
    description: `Get one work_order`,
    requestFormat: "json",
    parameters: [
      {
        name: "woorID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    city: z.string().nullable(),
                    complement: z.string().nullable(),
                    latitude: z.number().nullable(),
                    longitude: z.number().nullable(),
                    neighborhood: z.string().nullable(),
                    number: z.string().nullable(),
                    state: z.string().nullable(),
                    street: z.string().nullable(),
                    zipCode: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                attachment: z.string(),
                classification: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                endAt: z.string().nullish(),
                event: z.string(),
                extraordinaryEffective: z.number(),
                fieldAgents: z.array(
                  github_com_falqondev_PMSPDS_backend_service_FieldAgentInfo
                ),
                generalEffective: z.number(),
                instID: z.string(),
                institutionName: z.string(),
                ordinaryEffective: z.number(),
                planningIdentifier: z.string(),
                programName: z.string(),
                recoID: z.number().nullish(),
                requester: z
                  .object({
                    documentNumber: z.string().nullable(),
                    documentType: z.string().nullable(),
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                requesterCompanyName: z.string().nullish(),
                requesterRole: z.string(),
                requesterType: z.string(),
                startAt: z.string().nullish(),
                status: z.string(),
                statusInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                statusReason: z.string().nullish(),
                subprogramName: z.string(),
                uid: z.string(),
                updatedAt: z.string(),
                urbanEquipmentName: z.string(),
                ureqID: z.number().nullish(),
                userID: z.string(),
                woclID: z.number().nullish(),
                wodoID: z.number().nullish(),
                wofaID: z.number().nullish(),
                woorID: z.string(),
                woprID: z.number().nullish(),
                worfID: z.number().nullish(),
                wortID: z.number().nullish(),
                wostID: z.number(),
                wosuID: z.number().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "put",
    path: "/api/work-orders/:woorID",
    alias: "workOrderUpdate",
    description: `Update work_order`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: workOrderCreate_Body,
      },
      {
        name: "woorID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    city: z.string().nullable(),
                    complement: z.string().nullable(),
                    latitude: z.number().nullable(),
                    longitude: z.number().nullable(),
                    neighborhood: z.string().nullable(),
                    number: z.string().nullable(),
                    state: z.string().nullable(),
                    street: z.string().nullable(),
                    zipCode: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                attachment: z.string(),
                classification: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                endAt: z.string().nullish(),
                event: z.string(),
                extraordinaryEffective: z.number(),
                fieldAgents: z.array(
                  github_com_falqondev_PMSPDS_backend_service_FieldAgentInfo
                ),
                generalEffective: z.number(),
                instID: z.string(),
                institutionName: z.string(),
                ordinaryEffective: z.number(),
                planningIdentifier: z.string(),
                programName: z.string(),
                recoID: z.number().nullish(),
                requester: z
                  .object({
                    documentNumber: z.string().nullable(),
                    documentType: z.string().nullable(),
                    name: z.string().nullable(),
                    phone: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                requesterCompanyName: z.string().nullish(),
                requesterRole: z.string(),
                requesterType: z.string(),
                startAt: z.string().nullish(),
                status: z.string(),
                statusInfo: z
                  .object({
                    displayOptions: z
                      .object({ color: z.string() })
                      .passthrough()
                      .nullable(),
                  })
                  .partial()
                  .passthrough(),
                statusReason: z.string().nullish(),
                subprogramName: z.string(),
                uid: z.string(),
                updatedAt: z.string(),
                urbanEquipmentName: z.string(),
                ureqID: z.number().nullish(),
                userID: z.string(),
                woclID: z.number().nullish(),
                wodoID: z.number().nullish(),
                wofaID: z.number().nullish(),
                woorID: z.string(),
                woprID: z.number().nullish(),
                worfID: z.number().nullish(),
                wortID: z.number().nullish(),
                wostID: z.number(),
                wosuID: z.number().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-orders/:woorID/documents",
    alias: "workOrderDocumentList",
    description: `Lists all work_order documents`,
    requestFormat: "json",
    parameters: [
      {
        name: "woorID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "wodoID",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "doteID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "docuID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_falqondev_PMSPDS_backend_service_WorkOrderDocumentView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/work-orders/download",
    alias: "workOrderDownload",
    description: `Get work order export file`,
    requestFormat: "json",
    parameters: [
      {
        name: "requesterType",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "classification",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "requesterRole",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "startAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "urbanEquipmentName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "endAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "woorID",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "startAt[lte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "institutionName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "status",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "requesterName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "requesterCompanyName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "instID",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().nullish(),
      },
      {
        name: "endAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "uid",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "programName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "createdAt[gte]",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "uIDs",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "subprogramName",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
